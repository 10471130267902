@import "../../../../style-variables.scss";

.root
{
  display: inline-block;
  position: relative;

  .avatar
  {
    position: absolute;
  }

  .subAvatar
  {
    position: absolute;
    bottom: -6px;
    right: -6px;
    border-radius: 50%;
    border: 2px solid $background-color;

    &.subAvatarBackgroundInCard
    {
      border: 2px solid white;
    }
  }
}
