@import "../../../../../../style-variables.scss";

.root
{
  height: 332px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover
  {
    background-color: $hover-background-color;
    cursor: pointer;
  }

  .container
  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .icon
  {
    margin-bottom: 15px;
  }

  .label
  {
    font-weight: 500;
    font-size: 16px;
  }
}
