@import "../../../../../style-variables.scss";

.moreButton
{
  position: relative;
  right: 0px;
  bottom: 0;
  color: $primary-color;
  font-size: 12px;
}

.moreChevron
{
  visibility: hidden;
}

.header
{

}

.toolbar
{
  &.header
  {
    &:not(.expanded)
    {
      padding-bottom: 5px;
    }
  }

  &.expanded
  {
    padding-bottom: 10px;

    .moreChevron
    {
      visibility: visible;
    }
  }

  &:hover
  {
    .moreChevron
    {
      visibility: visible;
    }
  }
}

.showAllContainer
{
  margin-bottom: 5px;
  font-size: 12px;
  color: $primary-color;
}

.list
{
  padding-top: 7px;
  padding-bottom: 7px;
}

.filter
{
  margin-right: 15px;
}
