@import "../../../../../style-variables";

.root
{
  cursor: pointer;
  padding: 10px 15px;

  &.read
  {

  }

  &.unread
  {
    background-color: $hover-background-color;

    &:hover
    {
      background-color: $darker-hover-background-color;
    }

    .header
    {
      font-weight: 500;
    }
  }

  .buttonGroup
  {
    visibility: hidden;
  }

  &:hover
  {
    background-color: $hover-background-color;

    .buttonGroup
    {
      visibility: visible;
    }
  }

  .header
  {

  }

  .date
  {
    font-size: 12px;
    color: $secondary-text-color;
  }
}
