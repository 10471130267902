@import "../../../../../../../../../style-variables";

.overdue
{
  color: $danger-color;
}

.finished
{
  color: $success-color;
}
