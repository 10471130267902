.summary
{
  cursor: pointer;

  &.expanded
  {
    .icon
    {
      visibility: visible;
    }
  }

  &:hover
  {
    .icon
    {
      visibility: visible;
    }
  }
}

.icon
{
  visibility: hidden;
}