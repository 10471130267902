.root
{
  cursor: default;

  .calendarWrapper
  {
    // height: 1150px;
    padding-top: 7px;
  }
}
