@import "../../../../../style-variables";

.editor
{
  width: 40px;
  display: inline-block;
  margin-right: 5px;
}

.label
{
  font-weight: 400;
  line-height: 1;
  color: $secondary-text-color;
  font-size: 12px;
}
