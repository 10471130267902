@import "../../../../../../style-variables.scss";

.root
{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header
{

}

.body
{
  flex: 1 1 auto;
  overflow-y: auto;
}
