@import "../../../../../style-variables";

.description
{
  font-size: 13px;
  color: $secondary-text-color;

  &.large
  {
    font-size: 14px;
  }
}