@import "../../../../../../../../../../../style-variables.scss";

.root
{
  color: $primary-color;
  cursor: pointer;

  &:hover
  {
    color: $dimmed-primary-color;
  }
}
