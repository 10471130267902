.root
{

}

.respondentHeader
{
  font-size: 14px;
  font-weight: 500;
}
