@import "../../../../../../style-variables";

.root
{
  background-color: $card-grey-background-color !important;

  .listContainer
  {
    padding: 0 5px 5px 5px;

    .item
    {
      padding: 10px;
      cursor: pointer;

      &:hover
      {
        background-color: $hover-background-color;
      }
    }
  }
}
