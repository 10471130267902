.root
{
  position: fixed;
  top: 150px;
  bottom: 0;
  right: 0;
  writing-mode: vertical-rl;

  .container
  {
    .handleContainer
    {
      .handle
      {
        transform: rotate(180deg);
        padding: 10px 2px;
        height: fit-content;

        background-color: #3497db;
        color: white;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  //&.open
  //{
  //  .handle
  //  {
  //    transform: translateX(calc(100% / -2.2)) rotate(90deg) translateY(-350px);
  //  }
  //}
}
