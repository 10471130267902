.root
{
  .headerContainer
  {
    position: relative;

    .headerOptions
    {
      position: absolute;
      right: 0;
      z-index: 1;
    }
  }

  &:not(.compact)
  {
    .tabBar
    {
      margin-left: 43px;
    }
  }

  &:not(.compact)
  {
    .data
    {
      .products
      {
        margin-left: 43px;
      }
    }
  }

  .data
  {
    margin-top: 15px;
  }

  &:not(.compact)
  {
    .loadMoreButton
    {
      margin-left: 65px;
    }
  }
}
