@import '../../../../../style-variables';

.field
{
  max-width: 120px;
}

.popper
{
  width: 120px;

  .card
  {

  }

  .menu
  {
    max-height: 300px;
    overflow: scroll !important;

    &.hourMenu
    {
      border-right: 1px solid $divider-color;
    }
  }
}

//.root
//{
//  width: 100% !important;
//  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
//  margin-bottom: 0px !important;
//  background-color: transparent !important;
//  box-sizing: content-box !important;
//  padding-bottom: 0px !important;
//  padding-top: 0px !important;
//  margin-top: 0px !important;
//  width: 120px !important;
//  line-height: 1 !important;
//
//  &:hover {
//    border-bottom: 2px solid #000;
//    margin-bottom: -1px;
//  }
//
//  :global(.ant-picker-input) {
//
//    input
//    {
//      border: 0;
//      border-radius: 0;
//      padding: 0;
//      height: 18px;
//      background: transparent;
//
//      font-size: $input-font-size;
//      font-weight: $input-font-size;
//      font-family: $font-stack;
//      color: #565a5c;
//    }
//  }
//
//  :global(.ant-time-picker-icon) {
//    display: none;
//  }
//}
//
//:global(.ant-time-picker-panel)
//{
//  z-index: 10000;
//}
//
//:global(.Form-NoUnderline) :global(.ant-time-picker:not(:hover))
//{
//  border-bottom: 0;
//  margin-bottom: 2px;
//}
//


