@import "../../../../../style-variables";

.root
{
  &.labelLeft
  {
    display: flex;
    min-height: 27px;
    // max-width: 800px;
    align-items: center;
    min-width: 70px;

    &.labelFullWidth
    {
      >.labelContainer
      {
        flex: 1 1 auto;
      }
    }

    &:not(.labelFullWidth)
    {
      &:not(.inline)
      {
        >.labelContainer
        {
          width: 33%;
          max-width: 250px;
        }
      }

      >.viewContainer
      {
        flex: 1 1 auto;
      }
    }

    &:not(.inline)
    {
      >.labelContainer
      {
        min-width: 200px;
        padding-top: 3px;
      }
    }

    >.labelContainer
    {
      display: flex;
      padding-right: 10px;
    }

    >.viewContainer
    {
      display: flex;
      align-items: center;
      min-width: 0 // https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout
    }
  }

  position: relative;

  .label
  {
    font-size: 14px;
    font-weight: 400;
    color: $secondary-text-color;
  }

  &:hover
  {
    .label
    {
      color: $primary-color;
    }
  }

  .view
  {
    width: 100%;
    display: flex;
    align-items: center;

    .innerView
    {
      width: 100%;
    }
  }

  .help
  {
    position: relative;
    display: inline-block;
    cursor: help;

    &.helpTop
    {
      position: absolute;
      right: 0;
    }

    &.helpLeft
    {
      padding-left: 5px;
    }

    &.helpRight
    {
      padding-right: 5px;
    }
  }
}
