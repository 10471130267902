@import "../../../../../../style-variables";

.root
{
  margin: 5px 0;
}

.aspect
{
  color: $secondary-text-color;
  transform: rotate(280deg);
  margin: 5px -8px 5px 0;
  font-size: 13px;
  display: inline-block;
  width: 37px;
  text-align: left;
}
