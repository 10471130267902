@import "../../../../style-variables";

.root {
  padding-left: 16px !important;
}

.label {
  font-size: 13px !important;
  font-weight: 400 !important;
  cursor: initial !important;
  margin-right: 8px !important;
  background: transparent !important;
}

.dataType {
  font-size: 12px !important;
  font-weight: 300 !important;
  font-style: italic;
  cursor: initial !important;
  padding-left: 8px !important;
  background: transparent !important;
}

.treeItem {
  align-self: center !important;
}

.header {
  height: 65px;
}

.cardHeader {
  padding-left: 16px !important;
  padding-right: 8px !important;
}
