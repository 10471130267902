@import "../../../../../../style-variables.scss";

.root
{
  font-size: 13px;
  color: $secondary-text-color;

  .date
  {
    padding-top: 8px;
    font-size: 13px;
    color: $secondary-text-color;
  }
}