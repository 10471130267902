@import "../../../../../../style-variables.scss";

.title
{
  font-weight: 300 !important;
}

.root
{
  position: relative;

  .foreground
  {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    &:hover
    {
      backdrop-filter: blur(1px);
      cursor: pointer;
    }

    .addLabel
    {
      display: none;
      font-weight: 500;
      color: $primary-color;
    }

    &:hover
    {
      .addLabel
      {
        display: block;
      }
    }
  }

  .widget
  {

  }
}
