@import "../../../../../../style-variables.scss";

.root
{
  color: $primary-color;
  font-weight: 400;

  &.link
  {
    cursor: pointer;

    &:hover
    {
      // background-color: $hover-background-color;
      text-decoration: underline;
    }
  }
}
