@import "../../../../../../../style-variables.scss";

.root
{
  &.first
  {
    padding-left: 15px;
  }

  &.hidden
  {
    color: $light-text-color;
  }
}
