@import "../../../../../../../../style-variables";

.expansion
{
    border-top: 1px solid $divider-color;
}

.fieldItemLabel
{
    // color: $secondary-text-color;
    // font-size: 13px;
}
