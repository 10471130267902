@import "../../../../../../style-variables";

.root
{
  width: 100%;

  .section
  {
    overflow: initial !important;
  }

  .bar
  {
    cursor: col-resize;
    position: relative;

    .line1
    {
      position: absolute;
      top: 0;
      left: calc(50% - 2px);
      width: 1px;
      bottom: 0;
      background-color: rgba(0,0,0,0.05);
      display: none;
    }

    .line2
    {
      position: absolute;
      top: 0;
      left: calc(50% + 2px);
      width: 1px;
      bottom: 0;
      background-color: rgba(0,0,0,0.05);
      display: none;
    }

    &:hover
    {
      .line1
      {
        display: block;
      }

      .line2
      {
        display: block;
      }
    }

    //&:hover
    //{
    //  background-color: rgba(0,0,0,0.05);
    //}
  }
}
