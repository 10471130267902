@import "../../../../../style-variables.scss";

.root
{
  position: relative;

  .dropContainer
  {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 16px;
    border: 2px dashed rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    text-align: center;
    font-size: 21px;
    color: $secondary-text-color;
    cursor: pointer;

    &:hover
    {
      background-color: $hover-background-color;
    }

    .dragOverlay
    {
      border-radius: 10px;
    }
  }
}

.dragOverlay
{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  border: 4px dashed $primary-color;
  color: $primary-color;
  font-size: 30px;
}
