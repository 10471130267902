.dummy
{
  width: auto;
  display: inline-block;
  visibility: hidden;
  position: fixed;
  overflow: auto;

  // preserve white-spaces:
  white-space: pre-wrap;
}
