.root
{
  position: relative;

  &.compact
  {
    padding-top: 40px;

    .resourceSelectionFilter
    {
      position: absolute;
      top: -2px;
      right: 0;
    }
  }

  &:not(.compact)
  {
    .resourceSelectionFilter
    {
        position: absolute;
        top: -2px;
        right: 0;
        z-index: 3;

        &:not(.withViewSwitcherHidden)
        {
          right: 160px;
        }
    }
  }
}
