@import "../../../../../../style-variables.scss";

.root
{
  position: relative;
  border: 1px solid $divider-color;
  width: 100%;

  .clearButton
  {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
