@import "../../../../../../style-variables";

.root
{
  position: relative;
  background-color: $note-color !important;

  .menuButton
  {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .toolbar
  {

  }
}
