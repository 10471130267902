@import "../../../../style-variables.scss";

.root
{
  &.highlighted
  {
    background-color: $hover-background-color !important;
  }

  &.openable
  {
    .inset
    {
      cursor: pointer;
      padding: 4px 15px 0px 15px;

      &:hover
      {
        background-color: $hover-background-color !important;
        border-radius: 5px;
        // margin: 0px 10px 0px 10px;
        // padding: 4px 5px 0px 5px;
      }
    }
    .bottomInset
    {
      padding-bottom: 4px;
    }
  }

  .deleteButton
  {
    display: none;
  }

  &.editable
  {
    &:hover
    {
      .deleteButton
      {
        display: inline-block;
      }
    }
  }

  .favoriteButton
  {
      position: absolute;
      right: 12px;
      top: -7px;
      margin-top: -4px;
  }
}
