.root
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 2;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .content
  {
    width: 100%;
    max-width: 600px;

    .itemContainer
    {
      overflow-y: auto;
      max-height: 70vh;
    }
  }
}
