@import "../../../../../../../style-variables.scss";

.root
{
  &.nested
  {
    padding: 8px 0;
  }

  &:not(.nested)
  {
    color: $text-color;
    font-size: 14px;
  }
}
