@import "../../../../../../style-variables.scss";

.root
{
  display: flex;
  flex-direction: column;

  .header
  {

  }

  .content
  {
    margin-top: $page-margin;

    .left
    {
      width: 500px;
      margin-right: $page-margin / 2;
    }

    .right
    {
      margin-left: $page-margin / 2;
      flex: 1 1 auto;
    }

    .tabContent
    {
      margin-top: 15px;
    }
  }
}
