@import './../../../../style-variables.scss';

.root
{
  width: 100%;

  &:not(.maxHeightDisabled)
  {
    overflow-y: auto !important;
    max-height: calc(100vh - 92px); // Header height + 2x16 padding
  }
}
