.root
{
  &:not(.noScroll)
  {
    overflow-x: auto;
  }

  .table
  {
    min-width: 600px;

    td, th
    {
      white-space: nowrap;
      //border: 2px solid;
    }

    &.showMilestone
    {
      min-width: 800px;
    }
  }
}
