@import "src/style-variables";

.root
{
  .label
  {
    cursor: pointer;

    &:hover
    {
      text-decoration: underline;
    }
  }
}
