.reference
{
  &.fullWidth
  {
    width: 100%;
  }

  &.inline
  {
    display: inline;
  }
}

.popper
{
  position: relative;

  // Lower than selectbox portal (see share with colleagues popper in dashboard)
  z-index: 2;

  // Size is calculated in the wrong way on IE11 for popper
  // Possible other solution: https://stackoverflow.com/questions/55903695/popup-container-have-wrong-size-in-ie11
  // But for now, we simply do a min-width
  min-width: 200px;

  .popperContent
  {
    >div:last-child
    {
      max-width: 90vw;
      max-height: 90vh;
      overflow: auto !important;
    }
  }
}
