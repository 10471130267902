@import "../../../../../../../style-variables.scss";

.showAllContainer
{
  margin-bottom: 5px;
  font-size: 12px;
  color: $primary-color;
}

.list
{
  padding-top: 7px;
  padding-bottom: 7px;
}