@import "../../../../../../../style-variables.scss";

.root
{
  position: relative;
  margin-bottom: 30px;

  .openedItem
  {

  }

  &.itemOpen
  {
    .main
    {
      display: none;
    }
  }

  .main
  {
    .tabs
    {

    }

    .line
    {
      position: absolute;
      top: 30px;
      bottom: 16px;
      left: 30px;
      width: 2px;
      // border: 1px dashed $divider-color;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAOCAYAAAAFdM1fAAAAAXNSR0IArs4c6QAAABxJREFUCB1jYCAMGO/evfsfpIwJppYYBkwtPhoAPO8DonoGqJ0AAAAASUVORK5CYII=")
    }

    .content
    {
      position: relative;

      // margin-top: 15px;
      // flex: 1 1 auto;
      // overflow-y: scroll;

      .tabContent
      {

      }
    }
  }
  
  //&:not(.compact)
  //{
  //  .main
  //  {
  //    .content
  //    {
  //      .data
  //      {
  //        margin-left: 15px;
  //      }
  //    }
  //  }
  //}
}

.empty
{
  font-weight: 500;
  color: $secondary-text-color;
  margin-left: 25px;
  margin-top: 5px;
}
