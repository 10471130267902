@import 'src/style-variables';

.root
{
  cursor: pointer;
  background-color: $header-field-background-color;
  border-radius: 5px;
  padding: 4px 10px 4px 10px;
  display: flex;

  &:hover
  {
    background-color: $header-field-background-color;
  }

  .activity
  {
    flex: 1 1 auto;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
  }

  .icon
  {
    display: inline-block;
    margin: 0 0px 0 5px;
  }
}
