span[class*="TabIndicator-root"] {
  // height: 4px
}

button[class*="MuiTab-Root-"] {
  text-transform: inherit;
}

span[class*="MuiTab-label-"] {
  font-size: 0.8rem;
  font-weight: 500;
}
