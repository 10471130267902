@import "../../../../../../../style-variables";

.root
{
  padding: 10px;

  &.firstColumn
  {
    padding-left: 0;
  }

  &.lastColumn
  {
    padding-right: 0;
  }
}
