@import "../../../../../../../style-variables";

:global(.note)
{
  display: block;
  margin-right: 32px;

  p
  {
    margin: 0px;
  }

  :global(.ql-align-right)
  {
    text-align: right;
  }

  :global(.ql-align-center)
  {
    text-align: center;
  }

  ul, ol
  {
    margin: 0px;
  }

  ul > li {
    list-style-type: none;
  }
  ul > li::before {
    content: '\2022';
    margin-left: -0.6em;
    margin-right: 0.3em;
    text-align: right;
  }
  :global(.ql-size-small) {
    font-size: 0.75em;
    line-height: 1;
  }
  :global(.ql-size-large) {
    font-size: 1.5em;
    line-height: 1.1;
  }
  :global(.ql-size-huge) {
    font-size: 2.5em;
    line-height: 1.2;
  }
  :global(.computed) {
    min-width: 5px;
    border-bottom: 1px dotted;
  }

  ol > li, ul > li {
    list-style-type: none;
  }
  :global(ol li) {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }
  :global(ol li:before) {
    content: counter(list-0, decimal) '. ';
  }
  :global(ol li.ql-indent-1) {
    counter-increment: list-1;
  }
  :global(ol li.ql-indent-1:before) {
    content: counter(list-1, lower-alpha) '. ';
  }
  :global(ol li.ql-indent-1) {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  :global(ol li.ql-indent-2) {
    counter-increment: list-2;
  }
  :global(ol li.ql-indent-2:before) {
    content: counter(list-2, lower-roman) '. ';
  }
  :global(ol li.ql-indent-2) {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  :global(.ql-indent-1) {
    margin-left: 50px
  }
  :global(.ql-indent-2) {
    margin-left: 100px
  }
  :global(.ql-indent-3) {
    margin-left: 150px
  }
  :global(.ql-indent-4) {
    margin-left: 200px
  }
  :global(.ql-indent-5) {
    margin-left: 250px
  }
  :global(.ql-indent-6) {
    margin-left: 300px
  }
  :global(.ql-indent-7) {
    margin-left: 350px
  }
  :global(.ql-indent-8) {
    margin-left: 400px
  }
  :global(.ql-indent-9) {
    margin-left: 450px
  }
}

.root
{
  display: flex;
  flex-direction: column;

  .header
  {
    display: flex;
    flex-direction: row;
    align-items: center;

    .creator
    {
      display: inline-block;
      float: left;
      margin-right: 5px;
      font-weight: 500;
    }

    .noteDate
    {
      display: inline-block;
      padding-left: 9px;
    }
  }

  .content
  {
    overflow: auto;
  }
}
