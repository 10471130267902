.selector
{
  margin: 0px 7px 7px;
}

.overline
{
  margin: 5px 11px;
  margin-top: 13px;
}

.buckets
{
  display: flex;
  flex-wrap: wrap;
}