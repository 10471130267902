.edit-post-editor__inserter-panel,
.edit-post-editor__list-view-panel {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.edit-post-editor__list-view-panel {
	// Same width as the Inserter.
	// @see packages/block-editor/src/components/inserter/style.scss
	min-width: 350px;
}

.edit-post-editor__inserter-panel-header {
	padding-top: 10px;
	padding-right: 10px;
	display: flex;
	justify-content: flex-end;

	//@include break-medium() {
	//	display: none;
	//}
}

.edit-post-editor__inserter-panel-content,
.edit-post-editor__list-view-panel-content {
	// Leave space for the close button
	//height: calc(100% - #{$button-size} - #{$grid-unit-10});
}

.edit-post-editor__inserter-panel-content {
	//@include break-medium() {
	//	height: 100%;
	//}
}

.edit-post-editor__list-view-panel-header {
	align-items: center;
	border-bottom: 1px solid #ececec;
	display: flex;
	justify-content: space-between;
	height: 60px;
	padding-left: 20px;
	padding-right: 5px;
}

.edit-post-editor__list-view-panel-content {
	overflow-y: auto;
	// The table cells use an extra pixels of space left and right. We compensate for that here.
	// padding: $grid-unit-10 ($grid-unit-10 - $border-width - $border-width);
	padding: 10px 8px;
}
