@import "../../../../style-variables";

.root
{
  &.focused
  {
    background-color: #fffcc5;
    border-radius: 5px;
    padding: 1px 5px;
    border: 1px solid #ececec;
  }
}
