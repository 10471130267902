@import "../../../../../style-variables";

.root
{
  padding: 8px 25px !important;
  width: 100%;
  justify-content: flex-start !important;
  text-align: left;

  &:hover
  {
    background-color: $hover-background-color;
  }

  .icon
  {
    width: 30px;
  }

  .name
  {
    font-weight: 500;
  }

  &.checked
  {
    .name
    {
      text-decoration: line-through;
      color: rgba(0,0,0,0.40);
    }
  }
}
