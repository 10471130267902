@import "../../../../../../../style-variables";

.root
{
  padding: 10px 15px;
  cursor: pointer;

  &:hover
  {
    background-color: $hover-background-color;
  }

  .statusLabel
  {
    color: $secondary-text-color;
  }
}
