.hidden
{
  visibility: hidden;
  height: 0px;
  width: 0px;
}

.menu
{
  display: flex;
  flex-direction: column;
}

.actionHasCalendarItemInput
{
  margin-left: 15px;
}
