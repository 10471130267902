.editor
{
  padding: 8px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.03);

  &.white
  {
    background-color: #fff!important;
    border: 1px solid #e6e6e6!important;
  }
}
