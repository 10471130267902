@import "../../../../../../../../../../style-variables";

.root
{
  cursor: pointer;
  padding: 10px 15px;

  &:hover
  {
    background-color: $hover-background-color;
  }

  .title
  {
    font-weight: 500;
    color: $primary-color;
  }

  .percentage
  {
    font-weight: 500;
    color: $text-color;
  }

  .progress
  {
    &.positive
    {
      background-color: #c8e6c9;
    }

    .positiveBar
    {
      background-color: #4caf50;
    }

    &.negative
    {
      background-color: #ffcdd2;
    }

    .negativeBar
    {
      background-color: #f44336;
    }
  }

  .value
  {
    display: flex;
    align-items: center;
    color: rgba(0,0,0,0.65);
    font-weight: 500;

    .primary
    {
      font-size: 23px;
    }

    .secondary
    {
      margin-left: 10px;
      color: rgba(0, 0, 0, 0.4);
      font-weight: 400;
      font-size: 13px;
    }
  }
}
