@import '../../../../../style-variables';

.root
{
  .activeRegistration
  {
    cursor: pointer;
    // border: 1px solid #f5f5f5;
    background-color: $header-field-background-color;
    border-radius: 5px;
    padding: 4px 10px 4px 10px;
    color: #fff;

    &:hover
    {
      background-color: $header-field-background-color;
    }

    .icon
    {
      display: inline-block;
      margin: 0 5px 0 0;
    }

    .activity
    {
      display: inline-block;
      font-size: 15px;
      font-weight: 600;
      //border-right: 1px solid #ccc;
      //margin-right: 6px;
      //padding-right: 6px;
    }

    .duration
    {
      display: inline-block;
      margin-left: 8px;
      font-size: 15px;
      font-weight: 600;
    }
  }
}
