.card
{

  border-radius: 15px !important;
  padding: 8px;
  background-color: white;
  border: #e6e6e6 1px solid;

  .white
  {

  }

  &:not(.white)
  {
    background-color: #f7f7f7 !important;
    border-width: 0px !important;
  }
}


