@import "src/style-variables";

.root
{
  position: relative;
  word-break: break-all;
  width: 100%;

  &:not(.compact)
  {
    &:not(.highlighted)
    {
      width: calc(100% - 27px);
    }

    .invertedLineOnLastItem
    {
      position: absolute;
      top: 30px;
      bottom: 0;
      left: 15px;
      width: 1px;
      border: 1px solid $background-color;
    }
  }

  .icon
  {
    margin-left: -4px;
    margin-top: 1px;
    float: left;
  }

  &.constructorItem
  {
    .icon
    {
      margin-top: 6px;
    }
  }

  .avatar
  {
    margin-left: 12px;
  }

  &:not(.compact)
  {
    &:not(.highlighted)
    {
      margin-left: 16px;

      .data
      {
        margin-left: 49px;
        width: calc(100% - 38px);
      }
    }
  }

  .data
  {
    .description
    {
      margin-top: 3px;
      text-align: right;
    }

    .rootCard
    {
      color: $secondary-text-color;
    }

    .nestedRootCard
    {
      margin-left: 25px;
    }

    .nestedCard
    {
      margin-left: 20px;
    }

    .cardSection
    {
      position: relative;

      // Align times with times displayed in cards
      padding-right: 9px;

      .icon
      {
        position: absolute;
        margin-left: -20px;
        margin-top: 8px;
      }
    }
  }

  .notes
  {
    margin: 10px 0;
  }

  .noteCard
  {
    background-color: $note-color;
  }

  &:not(.compact)
  {
    &:not(.highlighted)
    {
      .header
      {
        &:after
        {
          content: "";
          position: absolute;
          left: 43px;
          top: 14px;
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-left: 1px solid $divider-color;
          border-bottom: 1px solid $divider-color;
          transform: rotate(45deg);
        }
      }

      &.constructorItem
      {
        .header
        {
          &:after
          {
            top: 20px
          }
        }
      }
    }

    .noteCard
    {
      .header
      {
        &:after
        {
          background-color: $note-color;
        }
      }
    }

    &.clickable
    {
      .header
      {
        &:hover
        {
          &:after
          {
            background-color: $hover-background-color;
          }
        }
      }
    }
  }
}

