.root
{
  // Was used for performance increase, but does not make a difference anymore
  // (and this does not work on iOS and MacOS)
  //position: absolute;
  //top: 0;
  //right: 0;
  //bottom: 0;
  //left: 0;
  //overflow: auto;

  display: flex;
  position: relative;

  .loader
  {
    position: absolute;
    bottom: 15px;
    left: 50%;
    right: 50%;
  }
}
