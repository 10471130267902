.root
{
  font-family: Roboto, sans-serif;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  word-break: break-word;
}

.dense
{
  line-height: 14px;
}
