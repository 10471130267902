.root
{
  width: 100%;

  .input
  {
    width: 100%;
    margin-bottom: 2px;

    &:hover
    {
      border-bottom: black 2px solid;
      margin-bottom: 0px;
    }

    input
    {
      font-size: 18px;
      font-weight: 600;
    }

  }
}
