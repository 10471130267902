$buttonColor: #f3f3f3;
$activeButtonColor: #a5a5a5;

.root
{
  position: relative;
  height: 100%;

  *
  {
    user-select: none;
    z-index: 0;
  }

  .viewToggleButton
  {
    position: absolute;
    top: 5px;
    right: 0;
  }

  .loadingOverlay
  {
    display: none;
    position: absolute;
    left: 0;
    top: 35px;
    right: 0;
    bottom: 0;
    background-color: transparent;
    cursor: wait;
    // pointer-events: none;
    z-index: 1000;

    >div
    {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -20px;
      margin-top: -20px;
    }
  }

  &.hideHeader
  {
    .loadingOverlay
    {
      top: 0;
    }
  }

  &.loading
  {
    .loadingOverlay
    {
      display: block;
    }
  }

  [class~="fc-row"]
  {
    overflow: hidden;
    overflow-y: scroll;
  }

  [class~="fc-header-toolbar"]
  {
    // padding-right: 30px;
    margin-bottom: 10px;
    // justify-content: flex-start;

    [class~="fc-left"]
    {
      display: flex;
      align-items: center;

      h2
      {
        display: inline-block;
        padding-left: 10px;
        font-size: 1.2em;
      }
    }
  }

  &.hideHeader
  {
    [class~="fc-header-toolbar"]
    {
      display: none;
    }

    .viewToggleButton
    {
      display: none;
    }
  }

  [class~="fc-day-grid-event"]
  {
    [class~="fc-time"]
    {
      display: none;
    }
  }

  // Remove stying from navigation buttons
  [class~="fc-button-primary"]
  {
    //color: unset !important;
    //background-color: unset !important;
    //border: none !important;
    //box-shadow: none !important;

    color: #717171 !important;
    background-color: $buttonColor !important;
    border: none !important;
    box-shadow: none !important;
    padding: 3px 8px 3px 8px;
    font-size: 12px;
    z-index: unset !important;

    &[class~="fc-button-active"]
    {
      background-color: $activeButtonColor !important;
      color: #fff !important;
    }

    &:disabled
    {
      display: none;
    }
  }

  &.viewMonth
  {
    [class~="fc-customMonth-button"]
    {
      background-color: $activeButtonColor !important;
      color: #fff !important;
    }
  }

  &.viewWeek
  {
    [class~="fc-customWeek-button"]
    {
      background-color: $activeButtonColor !important;
      color: #fff !important;
    }
  }

  &.viewDay
  {
    [class~="fc-customDay-button"]
    {
      background-color: $activeButtonColor !important;
      color: #fff !important;
    }
  }

  /*
  FullCalendar styles

  Copied from node_module source, replaced classes with the regex below to
  make sure the production minification process doesn't change the class names.

  Find: \.fc([a-z\-]*)
  Replace: [class~="fc$1"]
  */

  @import 'css/core/main.transformed.min.css';
  @import "css/daygrid/main.transformed.min.css";
  @import "css/timegrid/main.transformed.min.css";
}
