@import "../../../../../../style-variables.scss";

.root
{
  position: relative;

  i
  {
    color: rgba(0, 0, 0, 0.54);
  }

  .menuButtonContainer
  {
    &.hasInset
    {
      margin-right: -10px !important;
      margin-left: 0px !important;
    }
  }

  .name
  {
    font-weight: 500;
  }

  .description
  {
    font-size: 13px;
    color: $light-text-color;
  }

  .aggregate
  {
    text-align: right;
    font-weight: 500;
  }

  .caption
  {
    color: $light-text-color;
  }

  .date
  {
    font-weight: 500;
    font-size: 0.8em;

    &.overdue
    {
      color: #c40012;
    }
  }
}

.alignCenter
{
  align-self: center;
}

.assigneeSwitcher
{
  min-width: 130px;
}

.inline
{
  line-height: 1.2;
}

.inlineIcon
{
  padding-right: 3px;
}

.avatarWrapper
{
  margin-right: 10px !important;
}

.relativePosition
{
  position: relative;
}
.closed
{

  i
  {
    color: rgba(0, 0, 0, 0.2);
  }
}

.dateColumn
{
  min-width: 65px;
  text-align: right;

  .dashboard
  {
    min-height: 40px;
    display: flex;
    align-items: center;
  }
}

.headerSettingsWithPipeline
{
  margin-bottom: 5px;
}
