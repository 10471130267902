.referenceWithChildren
{
  display: block;
}

.title
{
  font-weight: 700;
  font-size: 14px;
  padding: 12px 16px;
}
