@import "src/style-variables";



.wrapper
{
  position: relative;
  width: 32px;
  height: 32px;
  cursor: pointer;

  .avatarRoot
  {
    position: absolute;
    border: 1px solid #ffffff5c;
    // border: #ffffff 2px solid;
    border-radius: 50%;
    cursor: pointer;
  }

  .overlay
  {
    position: absolute;
    width: 30px;
    height: 30px;
    background: black;
    border-radius: 50%;
    opacity: 0;
    cursor: pointer;
  }

  .overlayIcon
  {
    position: absolute;
    top: 4px;
    left: 3px;
    opacity: 0;
    cursor: pointer;
  }

  &:hover, &.open
  {
    >.overlay
    {
      opacity: 0.4;
    }

    >.overlayIcon
    {
      opacity: 1;
    }
  }


}



