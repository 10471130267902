@import "../../../../style-variables.scss";

.root
{
  overflow-x: hidden;

  &.scroll
  {
    overflow-x: auto;
  }

  .date
  {
    font-weight: 700;
    font-size: 1.2em;
    white-space: nowrap;
  }

  .buttonGroup
  {
    display: flex;
    align-items: center;

    .navButton
    {
      cursor: pointer;
      color: #717171 !important;
      border: none !important;
      box-shadow: none !important;
      padding: 3px;
      font-size: 12px;
      z-index: unset !important;
      white-space: nowrap;

      &:hover
      {
        background-color: #f3f3f3 !important;
      }
    }

    .button
    {
      cursor: pointer;
      color: #717171 !important;
      background-color: #f3f3f3 !important;
      border: none !important;
      box-shadow: none !important;
      padding: 3px 8px 3px 8px;
      font-size: 12px;
      z-index: unset !important;
      white-space: nowrap;

      &:first-child
      {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.selected
      {
        background-color: #a5a5a5 !important;
        color: #fff !important;
      }
    }
  }

  .planner
  {
    overflow-x: auto;

    .table
    {
      // https://stackoverflow.com/questions/2259189/table-overflowing-outside-of-div
      table-layout: fixed;
      min-width: 900px;

      .header
      {
        &.disabled
        {
          tr
          {
            height: 0 !important;

            th
            {
              padding: 0 !important;
            }
          }
        }
      }

      .resourceHeaderCell
      {
        width: 300px;
      }

      .totalHeaderCell
      {
        width: 70px;
        padding: 0 5px;

        .label
        {
          margin-bottom: -12px;
        }
      }

      .primaryHeaderCell
      {
        line-height: 1;
        position: relative;
        left: -28px;
      }

      .secondaryHeaderCell
      {
        line-height: 1;
        font-size: 11px;
        color: $secondary-text-color;
        font-weight: 400;
        position: relative;
        left: -13px;
      }

      .totalRow
      {
        >td
        {
          font-weight: 500;
        }

        .totalLabelCell
        {
          border-top: 2px solid $divider-color;
          padding: 0 15px;
        }

        .totalTrackCell
        {
          border-top: 2px solid $divider-color;
        }

        .totalCell
        {
          border-top: 2px solid $divider-color;
          border-left: 2px solid $divider-color;
          padding: 0 5px;
        }
      }
    }
  }
}
