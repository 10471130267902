.horizontal
{
  padding-left: 15px;
  padding-right: 15px;
}

.vertical
{
  padding-top: 8px;
  padding-bottom: 8px;
}

.typeName
{
  font-size: 11px;
  color: #80868b;
  letter-spacing: 0.8px;
}
