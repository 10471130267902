@import "../../../../../style-variables";

.root
{
  padding: 0px 15px;
  cursor: pointer;

  &:hover
  {
    background-color: $hover-background-color;
  }
}
