.root
{
  >:empty:not(.hasRatio)
  {
    display: none;
  }

  >:empty:not(.hasRatio) + div
  {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }

  >div + div:empty:not(.hasRatio)
  {
    margin-right: 0px !important;
    margin-bottom: 0px !important;
  }

  >div.item:empty + div.glue
  {
    display: none;
  }

  >div.item:empty ~ div.glue
  {
    // display: none;
  }

  >div.glue + div.item:empty
  {
    display: none;
  }
}
