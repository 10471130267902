.buttonRoot
{
  margin-top: 2px !important;
}

.iconButton
{
  &:hover
  {
    color: #a6a6a6 !important;
  }
}
