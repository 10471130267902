@import "../../../../../style-variables.scss";

.root
{
  border-top-width: 0 !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  margin: 0 -1px -1px -1px;

  &:not(.disabled)
  {
    &:hover
    {
      border-top-width: 1px !important;
      margin-top: -1px;
    }
  }
}
