@import "../../../../../style-variables.scss";

.root
{
  position: relative;
  border-color: rgba(0,0,0,0);
  font-weight: 500;
  text-align: center;

  &.inside
  {
    margin: 0;

    &:not(:hover)
    {
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
    }
  }

  &.disabled
  {
    color: $light-text-color !important;
    cursor: default;
  }

  &:not(.disabled)
  {
    cursor: pointer;

    color: $primary-color !important;

    &:hover
    {
      border-color: $primary-color;
      background-color: $hover-background-color;
    }
  }

  .loader
  {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
