.header
{
  position: relative;

  .menu
  {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.fields
{
  margin-top: 5px;
  margin-right: 5px;
}
