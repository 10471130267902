@import "src/style-variables";

.root
{

}

.legendParentWrapper
{
  width:100%;
}

.legendParent {
  display: flex;
  flex-wrap: nowrap;
}

.legend {

}
