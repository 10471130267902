@import "../../../../style-variables.scss";

$border-width: 1px;

.buttonWrapper
{
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .overline, .underline
  {
    font-size: 9px;
    letter-spacing: 1.2px;
    font-weight: 500;
    color: $primary-color;
    text-transform: uppercase;

    &.xs
    {
      max-width: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .overline
  {
    padding-bottom: 3px;
  }

  .underline
  {
    padding-top: 3px;
  }
}

.root
{
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: $primary-color $border-width solid;
  box-sizing: border-box;
  background: white;
  overflow: hidden;
  padding-top: 4px;

  &.noTopPadding
  {
    padding-top: 0px;
  }


  .icon
  {
    color: $primary-color;
  }

  .count
  {
    font-size: 11px;
    color: $primary-color;
  }

  &.disabled
  {
    background-color: white;

    &:not(.active)
    {
      border: $dimmed-primary-color $border-width solid;
    }

    .icon
    {
      color: $dimmed-primary-color;
    }

    .count
    {
      color: $dimmed-primary-color;
    }

    &:hover
    {
      border: $primary-color $border-width solid;

      .icon
      {
        color: $primary-color;
      }

      .count
      {
        color: $primary-color;
      }
    }
  }

  &.active
  {
    background-color: $primary-color;

    .icon
    {
      color: white;
    }

    .count
    {
      color: white;
    }
  }

  &.small
  {
    width: 26px;
    height: 26px;
    padding-top: 0px;
  }

  &.empty
  {
    .count
    {
      .emptyLabel
      {
        &:before
        {
          content: "+";
          margin-left: 3px;
        }
      }
    }
  }

  &:hover
  {
    background-color: $dimmed-primary-color;
    cursor: pointer;
  }

}

.empty
{

}
