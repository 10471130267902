@import "../../../../../../../../../style-variables";

.statistic
{
  width: 280px;
  margin-bottom: 15px;

  .card
  {
    .header
    {
      cursor: pointer;
      padding: 10px 15px;

      &:hover
      {
        background-color: $hover-background-color;
      }

      .title
      {
        font-weight: 500;
      }

      .percentage
      {
        font-weight: 500;
        color: $text-color;
      }

      .progress
      {
        &.positive
        {
          background-color: #c8e6c9;
        }

        .positiveBar
        {
          background-color: #4caf50;
        }

        &.negative
        {
          background-color: #ffcdd2;
        }

        .negativeBar
        {
          background-color: #f44336;
        }
      }

      .value
      {
        display: flex;
        align-items: center;
        color: rgba(0,0,0,0.65);
        font-weight: 500;

        .primary
        {
          font-size: 23px;
        }

        .secondary
        {
          margin-left: 10px;
          color: $light-text-color;
          margin-bottom: 6px
        }
      }
    }

    .expansion
    {
      border-top: 1px solid $divider-color;
      padding: 5px 15px 10px 15px;
    }
  }
}
