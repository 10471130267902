@import "../../../../../style-variables";

.root
{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  z-index: 1;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 45px 3px 3px 3px;

  &:hover
  {
    background-color: rgba(52, 151, 219, 0.4);

    .icon
    {
      display: block;
    }
  }

  &.left
  {
    left: 0;
  }

  &.right
  {
    right: 0;
  }

  .icon
  {
    display: none;
    color: white;

    &.top
    {
      position: absolute;
      left: -1px;
      top: 10px;
    }

    &.bottom
    {
      position: absolute;
      left: -1px;
      bottom: 10px;
    }
  }
}
