@import "../../../../../../../style-variables.scss";

.root
{
  display: flex;
  flex-direction: column;
  height: 100%;

  .emptyLabel
  {
    text-align: center;
    text-transform: lowercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 13px;
    font-weight: 300;
    color: $secondary-text-color;
  }

  .dropzone
  {
    flex: 1 1 auto;
  }

  .spacer
  {
    height: 50px;
  }

  .addCard
  {
    border-color: rgba(0,0,0,0);
    background-color: transparent;
    cursor: pointer;

    &:hover
    {
      border-color: $primary-color;
      background-color: $hover-background-color;
    }
  }
}
