@import "../../../../../style-variables";

.date
{
  font-size: 11px;
  color: $secondary-text-color;
}

.wrapper
{
  margin: 0px;
  width: 100%;
  overflow: hidden;

  // Add a min-height to the date picker, because its contents are positioned in an absolute manner
  // This results in our Popper implementation not positioning the element correctly. So we add a minimal height
  // (the height may differ for different months) such that the Popper implementation can do its job correctly.
  min-height: 300px;

  .positioner
  {
    margin-top: -18px;
    margin-left: -22px;
    margin-right: -23px;
    margin-bottom: -23px;

    :global
    {
      .DayPicker__horizontal
      {
        background: transparent;
      }

      //Reason for index in .CalendarMonthGrid & .DayPickerNavigation: https://github.com/react-dates/react-dates/issues/1462
      .CalendarMonthGrid
      {
        background: transparent;
        padding: 0 0 0 9px !important;
        z-index: 2;
      }

      .DayPickerNavigation {
        z-index: 3;
      }

      .CalendarMonth
      {
        background: transparent;
      }

      .CalendarDay__selected
      {
        background-color: $primary-color;
        border-color: $primary-color;
      }

      .CalendarDay__today:not(.CalendarDay__selected)
      {
        background-color: $plan-today-color;
        border-color: $plan-today-color;
        color: $plan-today-text-color;
      }
    }
  }
}
