@import "../../../../../../style-variables";

.root
{
  position: relative;

  .menuButton
  {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }
}
