@import "../../../../style-variables.scss";

.card
{
  &:not(.white)
  {
    background-color: $card-grey-background-color !important;
  }
}

.header
{
  padding: 0 10px 0 15px;

  //.headerButtons
  //{
  //  display: none;
  //}
  //
  //&:hover
  //{
  //  .headerButtons
  //  {
  //    display: block;
  //  }
  //}
}

.title
{
  font-weight: 400;
}

.deleteButtonMenu
{
  width: 100%;
}

.autoReloadSwitch
{
  margin: 10px 20px;
  max-width: 270px;
}
