@import "src/style-variables";

.suffix
{
  margin-top: -2px !important;
}

.inputGroup
{
  display: block;
  overflow: hidden;
}
