@import "../../../../../../../../../../style-variables";

.label
{
  padding-top: 5px;
}

.side
{
  border: 1px solid $divider-color;
  border-radius: 15px;
  min-width: 100px;
  padding-left: 15px;
}
