.root
{
}

.parentEntityName
{
    font-weight: 500;
}

.strikethrough
{
    text-decoration: line-through;
}
