@import "../../../../../../style-variables";

.addFieldButton
{
  border-top-width: 0 !important;

  &:hover
  {
    border-top-width: 1px !important;
  }

  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  margin: 0 -1px -1px -1px;
}

.designerContainer
{
  background-color: $card-grey-background-color;
  padding: 20px;

  .designerCard
  {
    max-width: 800px;
  }
}
