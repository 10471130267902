.header
{
  position: relative;

  .addButton
  {
    position: absolute;
    top: 0;
    right: 0;
  }

  .filterButton
  {
    position: absolute;
    top: 0;
    right: 25px;
  }
}
