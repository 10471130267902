@import "../../../../../../../style-variables.scss";

.header
{
  position: relative;

  .typeName
  {
    margin-bottom: 0px;
  }

  .parentRelation
  {
    font-weight: 500;
    font-size: 13px;
    color: $secondary-text-color;

    .icon
    {
      margin-right: 6px;
    }
  }

  .details
  {
    display: flex;
    flex-direction: row;

    .avatar
    {
      margin-left: 10px;
    }

    .content
    {
      .name
      {

      }

      .summary
      {
        margin-top: 5px;
      }
    }
  }

  .menu
  {
    position: absolute;
    top: 0;
    right: 0;
  }
}
