.root
{
  // The background color of the element where Quill resides in must be transparent, otherwise
  // it will set the inherited background color (usually white) as text background when selecting,
  // dragging & dropping formatted text
  background-color: transparent;

  position: relative;
  width: 100%;

  .quillWrapper
  {
    width: 100%;
    display: flex;
    padding-right: 32px;
  }

  .collapseButton
  {
    position: absolute;
    right: 4px;
    top: 3px;

    &.hidden
    {
      display: none;
    }
  }
  .toolbar
  {

  }
}
