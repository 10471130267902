.root
{
  &.top
  {
    padding-top: 10px;
  }

  &.bottom
  {
    padding-bottom: 10px;
  }

  &.left
  {
    padding-left: 15px;
  }

  &.right
  {
    padding-right: 15px;
  }
}
