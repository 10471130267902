@import "../../../../../../../../../style-variables.scss";

.root
{
  pointer-events: none;

  &:hover
  {
    background-color: $dimmed-primary-color;
  }
}
