@import "../../../../../style-variables.scss";

.row
{
  &.open
  {
    >.resourceCell
    {
      border-bottom: 0;
    }
    >.trackCell
    {
      border-bottom: 0;
      font-weight: bold;
    }
  }
  &.nested.last
  {
    >.resourceCell
    {
      border-bottom: 0;
    }
    >.trackCell
    {
      border-bottom: 0;
    }
  }

  >.resourceCell
  {
    line-height: 1 !important; // to center avatar letters
    padding: 0 15px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .resourceName
    {
      overflow: hidden;
      text-overflow: ellipsis;

      &.open
      {
        font-weight: 500;
      }
    }
  }

  >.totalCell
  {
    border-left: 2px solid $divider-color;
    padding: 0 5px !important;
    font-weight: 500;
  }

  &.nested
  {
    >.totalCell
    {
      font-weight: 400;
    }
  }
}

.expansionRow
{
  // This is to remove double lines
  >.cell
  {
    border-bottom: 0;

    >div
    {
      border-bottom: 1px solid $divider-color;
      overflow: hidden;
    }
  }
}
