.header
{
  position: relative;
  margin-bottom: 10px;



  .menu
  {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.tableContainer
{
  &:not(.noScroll)
  {
    overflow-x: auto;
  }

  .table
  {
    min-width: 800px;
  }
}
