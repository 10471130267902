@import "../../../../../../style-variables";

.root
{
  padding: 0 $page-margin;

  &.mobile
  {
    padding: 0 8px; // 8px is the minimum not to cause a horizontal scrollbar on mobile devices
    padding-top: 5px;
  }
}

.fixed
{
  position: fixed;
  height: 90%;
  overflow-y: hidden;
  padding-right: 5px;
  overflow-x: hidden;
  padding-top: 3px; // Needed for favorite button
}

.fixed:hover
{
  overflow-y: auto;
  padding-right: 0;
}

.fixed2
{
  position: fixed;
  height: 90%;
  overflow-x: hidden;
  padding-top: 3px; // Needed for favorite button
}
