@import "../.././../../../../style-variables.scss";

.root
{
  .addColumnButton
  {
    visibility: hidden;
  }

  &.editMode
  {
    .addColumnButton
    {
      visibility: visible;
    }
  }
}
