@import "../../../../../../style-variables.scss";

.new
{
  background-color: $note-color;
}

.open
{
  >td
  {
    border-bottom: 0;
  }
}
