.root
{

}

.dragOverlay
{
  border-radius: 10px;
  margin: -8px;
}
