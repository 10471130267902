.icon
{
  vertical-align: middle;
}

.rounded
{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.outlined
  {
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;
  }
}
