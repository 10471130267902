@import "../../../../../../../../style-variables";

.header
{
  display: flex;
  flex-direction: row;

  .name
  {
    flex: 1 1 auto;
  }

  .underline
  {
    font-size: 13px;
    font-weight: 400;
    color: $secondary-text-color;
  }
}

