@import "../../../../../../style-variables";

.root
{
  background-color: $card-grey-background-color !important;

  .iframe
  {
    height: 1000px;
    max-height: calc(100vh - 200px);
    border: 1px solid #ececec;

    &.video
    {
      height: 450px;
    }
  }
}
