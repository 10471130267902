@import "../../../../style-variables.scss";

.root
{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.bordered
  {
    border-bottom: 1px solid $divider-color;
  }

  .tabBar
  {
    flex: 1 1 auto;

    .flexContainer
    {
      &.centered
      {
        justify-content: center;
      }
    }

    .scrollButtons
    {
      width: 15px;

      svg
      {
        color: $secondary-text-color;
      }

      //span
      //{
      //  display: none;
      //}
    }

    //.scrollButtons:first-child
    //{
    //  svg
    //  {
    //    left: 5px;
    //  }
    //}
    //
    //.scrollButtons:last-child
    //{
    //  svg
    //  {
    //    right: 5px;
    //  }
    //}
  }

  .appendix
  {
    padding-right: 10px;
    //&.compact
    //{
    //  width: 100%;
    //  padding: 15px 15px 0 15px;
    //}
    //
    //&:not(.compact)
    //{
    //  position: absolute;
    //  right: 15px;
    //  top: 0;
    //  bottom: 0;
    //  display: flex;
    //  align-items: center;
    //}
  }
}
