@import "../../../../../style-variables.scss";

.root
{
  display: block;

  &:hover
  {
    background-color: rgb(230, 230, 230);
  }

  &.active
  {
    color: $primary-color;
  }

  .suffix
  {
    margin-left: 20px;
  }
}

.button
{
  padding: 7px 15px !important;
  width: 100%;
  justify-content: flex-start !important;

  .viewGroup
  {
    width: 100%;
  }
}

.label
{
  font-size: 14px;
  font-weight: 400;
  text-align: left;

  &.disabled
  {
    color: $secondary-text-color;
  }
}

.loading
{
  color: $light-text-color;
}
