.header
{
  position: relative;
  margin-bottom: 10px;

  .menu
  {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    display: flex;
    align-items: center;
  }
}
