@import "../../../../style-variables.scss";

.link
{
  &:not(.disabled)
  {
    cursor: pointer;

    &:hover
    {
      color: $primary-color;
    }
  }

  &.highlighted
  {
    color: $primary-color;

    &:not(.disabled)
    {
      &:hover
      {
        color: rgba(52, 151, 219, 0.6);
      }
    }
  }

  &.disabled
  {
    cursor: default;
    color: $secondary-text-color;
  }

  &.strikethrough
  {
    text-decoration: line-through;
  }
}
