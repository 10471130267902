@import './../../../../../../../style-variables.scss';

.root
{
  .preview
  {
    height: 300px;
    width: 300px;

    img
    {
      height: 300px;
      width: 300px;
      object-fit: contain;
    }

  }

  .filesize
  {
    font-size: 11px;
    color: $secondary-text-color;
    text-align: right;
  }

  &.listItem
  {
    .preview
    {
      height: 100px;
      width: 100px;
      max-height: 100px;

      img
      {
        height: 100px;
        width: 100px;
        //border: $divider-color 2px solid;
        //border-radius: 50px;
        object-fit: cover;
      }

      .icon
      {
        height: 100px;
        max-height: 100px;
        //border: $divider-color 2px solid;
        //border-radius: 50px;
      }
    }
  }

}
