@import "../../../../../style-variables.scss";

.viewerRoot
{
  position: relative;
  cursor: pointer;
  width: 100%;
  min-height: 100px;

  &:hover
  {
    .editOverlay
    {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.6);
      border: 1px solid $primary-color;

      .editLabel
      {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        color: $primary-color;
        font-weight: 500;
      }
    }
  }

  .editOverlay
  {
    display: none;
  }
}
