@import "../../../../../../../../../../style-variables";

.root
{
  font-size: 12px;
  color: $secondary-text-color;
  cursor: pointer;

  &:hover
  {
    color: $primary-color;
  }
}

.warning
{
  color: $warning-color;
}
