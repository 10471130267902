@import "../../../../../../../../../../style-variables.scss";

.side
{
  border: 1px solid $divider-color;
  border-radius: 15px;
  min-width: 100px;
  padding-left: 15px;
  min-height: 32px;
  display: flex;
  align-items: center;

  &.fixedType
  {
    padding-right: 15px;
  }
}
