@import "src/style-variables";

.root
{
  position: relative;
  box-sizing: border-box;
  border: 1px solid transparent;

  &.bordered
  {
    border: 1px solid $dimmed-primary-color;
  }

  .label
  {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    background-color: $primary-color;
    color: white;
    font-size: 10px;
  }

  .dragHandle
  {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;

    .line
    {
      position: absolute;

      background-color: $primary-color;
      display: none;
    }

    .icon
    {
      position: absolute;
      height: 16px;
      width: 16px;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      border: 1px solid $primary-color;
      background-color: $primary-color;
      color: white;
      // display: flex;
      display: none;
    }

    margin-left: -8px;
    margin-right: -8px;
    width: 16px;
    height: 100%;

    .line
    {
      top: 0;
      left: 6px;
      bottom: 0;
      width: 2px;
    }

    .icon
    {
      top: calc(50% - 8px);
    }

    &:hover
    {
      .line
      {
        display: block;
      }

      .icon
      {
        display: flex;
      }
    }

    //position: absolute;
    //top: 0;
    //bottom: 0;
    //left: 0;
    //width: 16px;
    //z-index: 1000;
    //
    //.line
    //{
    //  position: absolute;
    //
    //  background-color: $primary-color;
    //  display: none;
    //}
    //
    //.icon
    //{
    //  position: absolute;
    //  height: 16px;
    //  width: 16px;
    //  align-items: center;
    //  justify-content: center;
    //  border-radius: 16px;
    //  border: 1px solid $primary-color;
    //  background-color: $primary-color;
    //  color: white;
    //  display: none;
    //}
    //
    //.line
    //{
    //  top: 0;
    //  left: 0;
    //  bottom: 0;
    //  width: 5px;
    //}
    //
    //.icon
    //{
    //  top: calc(50% - 8px);
    //}
    //
    //&:hover
    //{
    //  .line
    //  {
    //    display: block;
    //  }
    //
    //  .icon
    //  {
    //    display: none;
    //  }
    //}
  }

  .controls
  {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;

    .line
    {
      position: absolute;

      background-color: $primary-color;
      display: none;
    }

    .icon
    {
      position: absolute;
      height: 16px;
      width: 16px;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      border: 1px solid $primary-color;
      background-color: $primary-color;
      color: white;
      // display: flex;
      display: none;
    }

    margin-left: -8px;
    margin-right: -8px;
    width: 16px;
    height: 100%;

    .line
    {
      top: 0;
      right: 6px;
      bottom: 0;
      width: 2px;
    }

    .icon
    {
      top: calc(50% - 8px);
      right: 0;
    }

    &:hover,&.open
    {
      .line
      {
        display: block;
      }

      .icon
      {
        display: flex;
      }
    }
  }

  &.horizontal.last
  {
    padding-right: 15px;
  }

  //&:hover
  //{
  //  border: 1px solid $primary-color;
  //
  //  .controls
  //  {
  //    display: block;
  //  }
  //}
}
