@import "../../../../../../../style-variables.scss";

.overline
{
  color: $secondary-text-color;
  font-size: 11px;
  letter-spacing: 0px;
  text-transform: uppercase;
  margin-bottom: -3px;
}

.title
{
  padding: 8px;

  &:hover
  {
    background: $hover-background-color;
    cursor: pointer;
  }
}
