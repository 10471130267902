@import '../../../../../style-variables';

.cardRoot
{
  z-index: 1000;
}

.timePicker
{
  width: 50px;
}

.duration
{
  .durationField
  {
    width: 30px;
    display: inline-block;
    margin-right: 5px;
  }

  .durationLabel
  {
    width: 50px;
    font-weight: 400;
    line-height: 1;
    color: $secondary-text-color;
    font-size: 12px;
  }
}

.resourcePlanner
{
  width: 60vw;
  padding-top: 10px;
}
