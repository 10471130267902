.root
{
  display: flex;
  flex-direction: row;

  >div:not(:first-child)
  {
    margin-left: -6px;
  }

  /*>div:not(:first-child)
  {
    margin-left: -4px;
  }

  >div:not(:last-child)
  {
    margin-right: -4px;
  }*/
}
