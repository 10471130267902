$unvisitedColor: #eeeeee;
$visitedColor: rgb(147, 206, 149);
$currentColor: rgb(74, 144, 226);
$unvisitedFontColor: rgb(158, 158, 158);

.root
{
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  cursor: default;

  &.clickable
  {
    cursor: pointer;
  }
}

.inner
{
  flex: 1 1 auto;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 0;

  /*&.visited
  {
    div { background-color: $visitedColor; }
    .first { background-color: $visitedColor; }
    .middle { background-color: $visitedColor; }
    .last { background-color: $visitedColor; }
  }

  &.current
  {
    div { background-color: $currentColor; }
    .first { background-color: $currentColor; }
    .middle { background-color: $currentColor; }
    .last { background-color: $currentColor; }
  }*/

  &.unvisited
  {
    /*div { background-color: $unvisitedColor; }
    .first { background-color: $unvisitedColor; }
    .middle { background-color: $unvisitedColor; }
    .last { background-color: $unvisitedColor; }*/

    &:not(.hasColor)
    {
      .name { color: $unvisitedFontColor; }
    }
  }

  &.firstRoot
  {
    .first
    {
      width: 20px;
    }

    .top
    {
      left: 15px;
    }

    .bottom
    {
      left: 15px;
    }
  }

  /*&.lastRoot
  {
    &.hasPhaseComponent
    {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      mask-image: radial-gradient(circle at right, transparent 0, transparent 10px, black 11px);
    }
  }*/
}

/*.first
{
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  bottom: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.last
{
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  bottom: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}*/

.lastIcon
{
  margin-left: -6px;
}

.middle
{

}

/*.top
{
  position: absolute;
  top: 0;
  left: 5.4px;
  right: 5.4px;
  bottom: 50%;
  transform: skew(45deg);
}

.bottom
{
  position: absolute;
  top: 50%;
  left: 5.4px;
  right: 5.4px;
  bottom: 0px;
  transform: skew(-45deg);
}*/

.name
{
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: white;
  text-transform: lowercase;
  white-space: nowrap;
  z-index: 1;
  width: 90%;
  text-align: center;
}

.phaseComponentWrapper
{
  margin-top: -2px;
}

.hasColor
{

}
