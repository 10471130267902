@import "../../../../../../style-variables.scss";

.name
{
  font-weight: 500;
}

.description
{
  font-size: 13px;
  color: $light-text-color;
}
