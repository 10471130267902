@import '../../../../style-variables.scss';


.fileDrop
{
  position: relative;
  height: 100%;

  .backdrop
  {
    display: none;
    width: 100%;
    height: 100vh;
    background: black;
    position: absolute;
    z-index: 1;
    opacity: 0.6;

    &.hovering
    {
      display: block;
    }
  }

  .dropArea
  {
    display: none;

    &.dropAreaDragging
    {
      display: flex;
      position: absolute;
      height: calc(100vh - 120px);
      top: 30px;
      left: 30px;
      right: 30px;
      background: #fff;
      opacity: 0.9;
      z-index: 1;
      border: $primary-color 4px dashed;
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 25px;
      color: $primary-color;
    }
  }
}
