@import "../../../../../style-variables";

.root
{
  display: flex;
  flex-direction: row;

  .icon
  {
    margin-left: 6px;
  }

  .avatar
  {
    margin-left: 12px;
  }

  .data
  {
    flex: 1 1 auto;
    margin-left: 15px;

    .description
    {
      margin-bottom: 3px;
    }

    .card
    {
      &.note
      {
        border-radius: 15px;
      }
    }

    .rootCard
    {
      color: $secondary-text-color;
    }

    .nestedRootCard
    {
      margin-left: 25px;
    }

    .nestedCard
    {
      margin-left: 20px;
    }

    .cardSection
    {
      position: relative;

      // Align times with times displayed in cards
      padding-right: 9px;

      .icon
      {
        position: absolute;
        margin-left: -20px;
        margin-top: 8px;
      }
    }
  }
}

