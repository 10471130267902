@import "../../../../../style-variables";

.root
{
  border-color: $primary-color !important;

  .disableUnderline
  {
    &:before
    {
      border-bottom: 0;
    }
  }
}

.input
{
  // Fix padding: in multiline and non-multiline this padding should be the same
  padding: 8px 10px !important;
  font-size: 14px !important;
}

