@import "../../../../../style-variables.scss";

.root
{
  color: $secondary-text-color;
  font-size: 13px;
  font-weight: 400;

  &:empty
  {
    display: none;
  }
}
