@import "../../../../../style-variables.scss";

.root
{
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  border-radius: 15px;
}
