.header
{
  .headerButtons
  {
    display: none;
  }

  &:hover
  {
    .headerButtons
    {
      display: block;
    }
  }
}