.root
{

}

[class~="block-editor-block-list__block"]
{
  .fileUploader
  {
    display: none;
  }

  &[class~="is-selected"]
  {
    .fileUploader
    {
      display: block;
      margin-top: 20px;
    }
  }
}
