@import "../../../../../../style-variables.scss";

.flag
{
  width: 16px;
  height: 16px;

  &.clickable
  {
    cursor: pointer;
  }
}
