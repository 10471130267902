.root
{
}

.relativePosition
{
  position: relative;
  min-height: 40px;
}

.contentFullHeight
{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.closeButtonWrapper
{
  position: absolute;
  right: 15px;
  top: 8px;
  z-index: 1;
}

