@import "../../../../../../../style-variables";

.root
{
  &.clickable
  {
    cursor: pointer;

    &:hover
    {
      background-color: $hover-background-color;
    }
  }

  &.highlighted
  {
    background-color: $hover-background-color;
  }
}


