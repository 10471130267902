@import "../../../../../../style-variables.scss";

.root
{
  position: relative;
  border-width: 0 !important;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  overflow: visible;
  border-radius: 0 !important;

  &.editable
  {
    &:hover
    {
      background-color: rgba(52, 151, 219, 0.3) !important;
    }
  }
}

.root:after
{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,.12);
  background: none;
  pointer-events: none;
  z-index: 1;
}
