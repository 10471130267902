@import "../../../../../../../../../style-variables";

.root
{
  cursor: pointer;

  &:hover
  {
    background-color: $hover-background-color;
  }

  &.open
  {
    background-color: $hover-background-color;
  }
}
