@import "../../../../../../../style-variables";

.dragSelector
{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.primaryHeader
{
  position: absolute;
  line-height: 1;
  font-weight: 500;
  font-size: 14px;
  color: $text-color;
  top: -28px;
  transform: translate(-50%, 0);
}

.secondaryHeader
{
  position: absolute;
  line-height: 1;
  font-size: 11px;
  color: $secondary-text-color;
  font-weight: 400;
  top: -13px;
  transform: translate(-50%, 0);
}

.timesheetSelector
{
  cursor: pointer;

  &.selected
  {
    background-color: $hover-background-color;
  }

  &:hover
  {
    background-color: $note-color;
  }

  .label
  {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
