@import "../../../../../../style-variables.scss";

.root
{
  font-weight: 500;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 11px;
  color: rgb(128, 134, 139);
  letter-spacing: 0.8px;
}
