
.pv-input {

  .ant-time-picker
  {
    display: initial;
    padding-bottom: 1px !important;
  }

  .ant-time-picker-input
  {
    height: 21px !important;
  }

  .ant-time-picker-panel
  {
    width: 112px;
  }
}
