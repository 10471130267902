.root
{
    &:not(.block)
  {
    display: inline-block;
  }

  &.fullWidth
  {
    width: 100%;
  }

  &.fullHeight
  {
    height: 100%;
  }
}
