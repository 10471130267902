.root
{

}

.viewGroup
{

  .avatar
  {

  }

  .btnEdit
  {
    display: none;
  }
}

.viewGroup
{
  &:hover
  {
    .btnEdit
    {
      display: block;
      position: absolute;
      top: 0px;
      right: 0px;

      &.white
      {
        background: white;
      }

      &.hideCard
      {
        top: -2px;
      }
    }
  }
}

.relativePosition
{
  position: relative;
}

.caption
{
  display: inline-block;
}
