.input
{
  &::-webkit-input-placeholder { /* Edge */
    color: white;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }

  &::placeholder {
    color: white;
  }
}
