@import "src/style-variables";

.root
{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: $secondary-text-color;

  .content
  {
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
  }

  font-size: 15px;
  font-weight: 400;

  &.selected
  {
    color: $primary-color;
  }

  &:hover
  {
    background-color: $hover-background-color;
    border-radius: 5px;
    color: $primary-color;
  }

  .icon
  {
    margin-right: 10px;
  }

  .name
  {

  }

  .closeButton
  {
    display: none;
  }

  &:hover
  {
    .closeButton
    {
      display: block;
    }
  }
}
