.percentageChart
{
  .nameLabel
  {
    font-size: 1.0em;
  }

  .valueLabel
  {
    font-size: 2em;
    font-weight: bold;
  }
}
