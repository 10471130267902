@import "../../../../../../../../style-variables";

.root
{
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 5px;
  padding: 3px 5px;
  color: white;
  cursor: pointer;
  font-size: 13px;
  height: 22px;
  display: flex;
  align-items: center;
  top: calc(50% - 11px);

  .resizeHandle
  {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 5px;
    cursor: col-resize;
  }
}
