@import "../../../../../../../style-variables";

.root
{
  padding: 10px 15px;

  &.allowManagement
  {
    cursor: pointer;

    &:hover
    {
      background-color: $hover-background-color;
    }
  }

  .statusLabel
  {
    color: $secondary-text-color;
  }
}
