.root
{

  &.header
  {
    padding-bottom: 10px;
  }

  .cardHeaderWrapper
  {
    position: relative;
  }

  .relationshipTypeSwitcherWrapper
  {

  }

  .summaryDivider
  {
      // margin-top: 10px;
      margin-bottom: 10px;
  }
}
