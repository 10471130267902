.card
{
  position: relative;
}

.navigator
{

}

.buttonGroup
{
  position: absolute;
  top: 7px;
  right: 15px;
  z-index: 1;
}

.favoriteButtonSpacing
{
  margin-right: 30px;
}
