@import "../../../../../style-variables";

.verticalButton
{
  height: 100%;
  border: 1px solid #3497db17 !important;
  min-width: 30px !important;
}

.root
{
  position: absolute;
  cursor: pointer;

  .line
  {
    position: absolute;

    background-color: $primary-color;
    display: none;
  }

  .icon
  {
    position: absolute;
    height: 16px;
    width: 16px;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    border: 1px solid $primary-color;
    background-color: $primary-color;
    color: white;
    display: none;
  }

  &.vertical
  {
    bottom: -10px;
    height: 20px;
    width: 100%;

    .line
    {
      bottom: 7px;
      left: 0;
      right: 0;
      height: 2px;
    }

    .icon
    {
      left: calc(50% - 8px);
      bottom: 0;
    }
  }

  &.horizontal
  {
    right: -8px;
    bottom: 0;
    width: 16px;
    height: 100%;

    .line
    {
      top: 0;
      right: 7px;
      bottom: 0;
      width: 2px;
    }

    .icon
    {
      top: calc(50% - 8px);
      right: 0;
    }
  }

  &:hover,&.open
  {
    .line
    {
      display: block;
    }

    .icon
    {
      display: flex;
    }
  }
}
