.root
{
    display: flex;
    flex-direction: column;
    position: relative;
}

.bar
{
    flex: 1 1 auto;
    display: flex;
    background-color: rgba(0,0,0,0.04);
    border-radius: 17px;
    margin: 8px;
    position: relative;
    height: 34px;
}

.inputContainer
{
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding-left: 16px;
}

.input
{
    font-size: 15px;
}

.innerInput
{
    padding: 0px;
}

.buttonContainer
{
    display: flex;
    position: relative;
    width: 34px;
    height: 34px;
    color: #ffffff33;
    align-items: center;
    justify-content: center;
}
