@import "../../../../../../style-variables.scss";

.root
{
  .moreButton
  {
    color: $primary-color;
    font-size: 12px;
    min-height: 20px;
  }

  .moreChevron
  {
    visibility: hidden;
  }

  &.expanded
  {
    .moreChevron
    {
      visibility: visible;
    }
  }
}