@import "../../../../../../style-variables";

.root
{
  display: flex;
  align-items: center;
  flex-direction: row;

  .bucket
  {
    flex: 1 1 auto;
    height: 50px;
    color: white;
    font-size: 16px;
    font-weight: 500;

    .overlay
    {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }

    &:hover
    {
      .overlay
      {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    .icon
    {
      margin-right: 5px;
    }
  }
}
