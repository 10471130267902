@import "../../../../../style-variables";

.root
{
  &:not(.disableUnderline)
  {
    border-bottom: 1px solid $form-field-underline-color;
    margin-bottom: -1px;
  }

  &:hover
  {
    border-bottom: 2px solid black;
    margin-bottom: -2px;
  }

  &.focused
  {
    border-bottom: 2px solid $primary-color;
    margin-bottom: -2px;
  }
}

.option
{
  // force menu item to have the width of the text element
  white-space: nowrap;

  // but do force a max width on items
  max-width: 70vw;

  .stringOption
  {
    padding: 5px 5px;
  }
}
