@import "../../../../../../style-variables.scss";

.root
{
  &.xs
  {
    min-width: unset !important;
    padding: 2px 5px !important;
    font-weight: 500 !important;
  }

  &.sm
  {
    min-width: unset !important;
    padding: 4px 5px !important;
    font-weight: 500 !important;
  }
}

.icon
{
  margin-right: 2px;
  font-size: 17px !important;

  &.xs
  {
    font-size: 12px !important;
  }

  &.sm
  {
    font-size: 14px !important;
  }

  &.disabled
  {
    color: $very-light-text-color;
  }
}

.label
{
  text-transform: initial;
  padding: 0px 5px;

  &.xs
  {
    font-size: 12px;
  }

  &.sm
  {
    font-size: 14px !important;
  }

  &.disabled
  {
    color: $very-light-text-color;
  }
}

.loader
{
  width: 100%;
}
