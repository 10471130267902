.search
{
  width: 100%;
  height: 100%;
  overflow: auto;

  &.hidden
  {
    display: none;
  }
}

.body
{
  height: 100%;

  &.search
  {
    display: none;
  }
}
