.root
{
  width: 30px;
  height: 30px;
  padding: 0px !important;

  .icon
  {
    font-size: 16px;
  }

  .loader
  {
    position: absolute;

    &.filled
    {
      color: white;
    }
  }
}
