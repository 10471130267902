@import "../../../../../../style-variables";

.root
{
  font-size: 22px;
  color: $primary-color;
  font-weight: 500;

  &.strikethrough
  {
    text-decoration: line-through;
  }

}


