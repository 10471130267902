$scrollbar-width:                   5px;
$font-stack:                        "Roboto", "Helvetica", "Arial", sans-serif;
$input-font-size:                   14px;
$input-font-weight:                 400;
$font-size:                         0.875rem;

$background-color:                  rgb(247, 247, 247);
$divider-color:                     rgb(230, 230, 230);
$form-field-underline-color:        rgba(0, 0, 0, 0.22);
$selected-background-color:         rgb(52, 151, 219); // rgb(26, 115, 232);
$selected-background-hover-color:   rgb(52, 151, 219);
$header-field-background-color:     rgba(255, 255, 255, 0.2);
$card-grey-background-color:        rgb(250, 250, 250);
$primary-color:                     rgb(52, 151, 219); // rgb(26, 115, 232);
$dimmed-primary-color:              #a3c7f5;
$secondary-color:                   #FF5722;

$very-light-text-color:             rgba(0, 0, 0, 0.2);
$light-text-color:                  rgba(0, 0, 0, 0.4);
$text-color:                        rgba(0, 0, 0, 0.87);
$medium-text-color:                 rgba(0, 0, 0, 0.65);
$secondary-text-color:              rgba(0, 0, 0, 0.54);

$plan-today-color:                  rgb(255, 204, 128);
$plan-today-text-color:             rgba(0, 0, 0, 0.4);

$attention-color:                   #d90000;

$success-color:                     #4caf50;
$warning-color:                     #ff9800;
$danger-color:                      #f44336;

$hover-background-color:            rgba(232, 240, 254, 1);
$visited-background-color:          rgba(241, 246, 255, 1);
$darker-hover-background-color:     rgba(219, 230, 254, 1);

$note-color:                        #fcf8e3;

$page-margin:                       16px;
