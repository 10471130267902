@import "../../../../../../../style-variables";

.preview
{
  color: $light-text-color;
}

.manuallySpecifyValueMappings
{
  &:not(.show)
  {
    visibility: hidden;
  }
}
