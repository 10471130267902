@import "../../../../../../style-variables.scss";

.root
{
  color: $text-color !important;

  &.link:hover
  {
    color: $primary-color !important;
  }

  &:not(.header)
  {
    font-size: 14px !important;
  }

  &.header
  {
    font-size: 16px;
    font-weight: 500;
    line-height: initial;
    letter-spacing: 0.47px;
  }
}
