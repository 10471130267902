.root
{
  position: relative;;
  display: inline-block;
  padding: 0 12px;
  // height: 22px;
  // line-height: 22px;
  border-radius: 15px;
  margin: 0;
  // margin-right: 8px;
  border: transparent 1px solid;
  transition: 0.1s;
  box-sizing: border-box;
  // margin-top: 1px;
  // margin-bottom: 1px;

  &.small
  {
    height: 19px;
    line-height: 19px;
    // margin-right: 0px;
  }

  &.clickable
  {
    cursor: pointer;
  }

  &.selected
  {
    border: 1px solid;
  }

  &.iconWrapper
  {
    padding-left: 5px;
  }

  .icon
  {
    padding-bottom: 3px;
    padding-right: 3px;
  }
}

.content
{
  font-size: 14px;
  font-weight: 500;
  //text-transform: uppercase;
  //position: inherit;
  // z-index: 1;
}
