@import "src/style-variables";

.headerCardWithShadow {
  position: relative;
  border-width: 0 !important;
  display: flex;
  width: 100%;
  height: 50px;
  background-color: $primary-color !important;
  overflow: visible;
  border-radius: 0 !important;
  color: white !important;
}

.headerCardWithShadow:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,.12);
  background: none;
  pointer-events: none;
  z-index: 1;
}

.viewGroup
{
  width: 100%;
}

.drawer
{
  height: 100%;
  overflow-y: auto;
}
