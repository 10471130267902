@import "../../../../../style-variables";

.preview
{
  color: $light-text-color;
}

.summaryExistent
{
  background-color: #bbdefb;
  color: #1565c0;
  font-weight: 500;
}

.summaryNew
{
  background-color: #c8e6c9;
  color: #2e7d32;
  font-weight: 500;
}
