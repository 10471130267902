@import "../../../../../../style-variables.scss";

.secondary
{
  font-weight: 400;
  font-size: 13px;

  &:not(.selected)
  {
    color: $secondary-text-color;
  }
}
