@import "../../../style-variables";

/** Select */

.Selectbox
{
  &:not(.future)
  {
    // border-bottom: 1px solid $form-field-underline-color;
    border-bottom: 0;
    margin-bottom: 1px !important;
    margin-top: 2px !important;

    &:hover:not(.disabled) {
      border-bottom: 2px solid #000000;
      margin-bottom: 0 !important;

      .react-select__dropdown-indicator
      {
        visibility: visible;
      }
    }

    &.is-focused {
      border-bottom: 2px solid #5398e4;
      margin-bottom: 0 !important;
    }

    .MultiSelectValueContainer {

      > div {
        padding: 0 !important;
      }

      li[class*="MuiListItem-root-"] {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      div[class*="MuiAvatar-root-"] {
        padding-right: 8px !important;
      }

      div[class*="MuiListItemText-root-"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .Select {
      margin-top: 0px;
    }
  }
}

:not(.Form-NoUnderline)
{
  .Selectbox:not(.is-focused):not(:hover):not(.future)
  {
    border-bottom: 1px solid $form-field-underline-color;
  }
}

.Form-NoUnderline {

  /*.Selectbox {
    margin-bottom: 2px;
    border-bottom: 0 !important;
    padding-bottom: 0 !important;
  }*/

  .Selectbox:not(.is-focused):not(:hover):not(.future)
  {
      margin-bottom: 0 !important;
      border-bottom: 0 !important;
      padding-bottom: 2px !important;
  }
}

.Selectbox.invalid:not(.future)
{
  border-bottom: 2px solid #F44336 !important;
  margin-bottom: 0 !important;
}

/** Bugfix for fixed positioning in Chrome within flex containers */
.Selectbox:not(.future) > div > div[class*="css-"]:not(.react-select__control):last-child
{
  position: sticky !important;
}

.Selectbox:not(.future)
{
  .react-select__value-container
  {
    padding-bottom: 0px !important;
    align-self: start !important;
    align-items: start !important;
  }

  .react-select__placeholder
  {
    color: rgba(0, 0, 0, 0.37) !important;
  }

  .react-select__single-value
  {
    align-self: start !important;
    p
    {
      font-size: $input-font-size !important;
      font-weight: $input-font-weight !important;
    }
  }

  .react-select__multi-value
  {
    align-items: center;
  }

  .react-select__placeholder
  {
    font-family: $font-stack;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
  }

  .react-select__indicators {
    line-height: 1 !important;
  }

  .react-select__value-container > div {
    line-height: 1 !important;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }

  &:hover {
    .react-select__clear-indicator {
      display: flex !important;
      visibility: visible;
    }
  }

  .react-select__clear-indicator {
    // display: none !important;
    display: flex !important;
    visibility: hidden;
    padding: 0 !important;
    color: #757575 !important;
    cursor: pointer;
  }

  .react-select__dropdown-indicator {
    padding: 0 !important;
    height: 12px !important;
    margin-bottom: 5px;
  }

  .react-select__input {
    line-height: 1 !important;

    >div {
      white-space: unset !important;
    }
  }

  .react-select__control {
    min-height: 17px !important;
  }

  .react-select__option > .selectbox-option {
    padding: 8px 12px !important;
  }

  .react-select__option > .selectbox-option.selected-item {
    background-color: #E0EBFF;
  }
}

.Selectbox-OpenByDefault {

  .Selectbox:not(.future) > div > div[class*="css-"]:not(.react-select__control):last-child
  {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    width: auto !important;
  }

  .Selectbox:not(.future)
  {
    .react-select__dropdown-indicator
    {
      display: none !important;
    }

    .react-select__control
    {
      display: none;
    }
  }

  &.Form-NoUnderline
  {
    .Selectbox:not(.future)
    {
      border-bottom: 0 !important;
    }
  }
}
