@import "../../../../../../../../style-variables.scss";

.root
{
  margin: 10px;

  .syncLabel
  {
    font-size: 12px;
    color: $secondary-text-color;
  }
}
