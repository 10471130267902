@import "../../../../../style-variables";

.used
{
  color: $light-text-color;
}

.preview
{
  color: $light-text-color;
}
