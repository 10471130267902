@import "src/style-variables";

.root
{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.disabled)
  {
    &.checkedTrue {
      background-color: $success-color;
    }

    &.checkedFalse {
      background-color: $danger-color;
    }

    &.checkedConditionallyTrue {
      background-color: #BAB86C;
    }

    &:hover {
      &:not(.isReadOnly) {
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }

  &.disabled
  {
    background-color: lightgrey;
  }

  .fill {
    background-color: whitesmoke;
    border-radius: 50%;
    width: 5px;
    height: 5px;
  }

  .predicate {
    width: 50px;
    height: 50px;
  }
}
