@import "../../../../../style-variables.scss";

.root
{
  .moreLabel
  {
    padding: 12px 0px;
    padding-right: 3px;
  }

  &.inset
  {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.expanded
  {
    .moreChevron
    {
      visibility: visible;
    }
  }

  &:hover
  {
    color: $primary-color;

    .moreChevron
    {
      visibility: visible;
    }
  }

  &.title
  {

  }

  &.icon
  {
    display: none;

    &:hover
    {
      display: block;
    }
  }
}

.moreLabel
{
  font-size: 13px;
  font-weight: 400;

  &.header
  {
    font-size: 14px;
    font-weight: 500;
  }
}

.moreChevron
{
  font-size: 16px;
  font-weight: 100;
}

.title
{

}
