.root
{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.column1
{
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name
{
  font-weight: 400;
  white-space: nowrap;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.54);
}

.value
{
  font-weight: 400;
  line-height: 1;
  color: rgb(155, 155, 155);
  font-size: 25px;
}

.countValue
{
  font-weight: 400;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 2px;
}
