.root
{
  cursor: pointer;

  .labelName
  {
    font-weight: bold;
  }

  .labelEmailCount
  {

  }

  .labelDate
  {
    font-size: 0.9em;
  }

  .labelPhase
  {
    font-weight: bold;
    font-size: 0.9em;
  }
}
