.search
{
  paddingTop: 10px;
}

.searchResult
{
  cursor: pointer;
}

.searchResultContainer
{
  max-height: 500px;
}

.header
{
  z-index: 10;
}
