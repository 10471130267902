.root
{
  display: inline-block;
  cursor: pointer;

  .iconWrapper
  {
    display: flex;
    // visibility: hidden;
  }

  //&:hover
  //{
  //  .iconWrapper
  //  {
  //    visibility: visible;
  //  }
  //}

  .label
  {
    display: inline-block;
    line-height: 1.0em;
  }

  .icon
  {
    display: inline-block;
    line-height: 1.0em;
  }
}
