@import "../../../../../../style-variables";

.root
{
  height: 100%;

  .list
  {
    height: 100%;
  }

  .add
  {
    visibility: hidden;

    &.mobile
    {
      visibility: visible;
    }
  }

  &:hover
  {
    .add
    {
      &:not(.dragging)
      {
        visibility: visible;
      }
    }
  }

  .addCard
  {
    margin: 10px;
    border-color: rgba(0, 0, 0, 0.1);
    background-color: rgba(255,255,255,0.8);
    cursor: pointer;

    &:hover
    {
      border-color: $primary-color;
      background-color: rgba(232, 240, 254, 0.9);
    }
  }
}
