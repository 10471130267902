.root
{
  position: relative;
}

.respondentHeader
{
  font-size: 14px;
  font-weight: 500;
  padding-top: 10px;
  padding-left: 14px;
  padding-bottom: 5px;
}

.respondentFieldContainer
{
  padding-top: 10px;
}

.editor
{
  padding: 40px;
  background-color: whitesmoke;
  border-top: 1px solid #ececec;
}
