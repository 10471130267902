@import "../../../style-variables.scss";

.root
{
  height: 100%;

  .body
  {
    display: flex;
    flex-direction: row;
    height: 100%;

    .sidebar
    {

    }

    .content
    {
      flex: 1 1 auto;
      width: 100%;

      &.padding
      {
        padding: $page-margin $page-margin 0 $page-margin;
      }
    }
  }
}
