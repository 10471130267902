@import "../../../.././../../../style-variables.scss";

.root
{
  padding: 10px 15px;

  .name
  {
    font-weight: 600;
    color: $text-color;
  }

  .aggregates
  {
    color: $light-text-color;
    text-transform: lowercase;
  }
}
