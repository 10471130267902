.root
{
  height: 100%;

  .container
  {
    height: 100%;

    .list
    {
      overflow: auto;
    }
  }
}
