@import "../../../../style-variables";

.option
{
  padding: 3px 0;
  min-width: 250px;

  &.secondary
  {
    color: $secondary-text-color;
  }
}

.name
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  min-width: 30px !important;
  margin: 0 !important;
}

.wrappedName
{
  white-space: pre-wrap;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leftSpacing
{
  margin: 0 !important;
  padding-left: 8px;
}

.rightSpacing
{
  padding-right: 12px;
}

.captionwrapper
{
  display: grid;
}

.caption
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  &.selected
  {
    color: white;

    .link
    {
      color: white;
    }
  }
}
