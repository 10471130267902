@import "../.././../../style-variables.scss";

.root
{
  display: flex;
  flex-direction: column;
  height: 100%;

  .header
  {
    margin: 0 0 10px 0;
  }

  .editor
  {
    margin: 0 0 15px 0;
  }

  .data
  {
    flex: 1 1 auto;
    position: relative;

    .cardAndScrollButtons
    {
      position: relative;

      .card
      {
        overflow-x: auto !important;
        overflow-y: hidden !important;
      }
    }
  }
}

.filters
{
  margin-bottom: 10px;
}

.filtersWrapper
{
  margin-bottom: 11px;
}
