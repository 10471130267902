@import "../../../../../../../style-variables";

.root
{
  font-weight: 600;
}

.link
{

}

.avatarRoot
{
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  margin-top: -2px;
}
