.card
{
  background-color: rgb(250, 250, 250) !important;
}

.header
{
  font-size: 15px;
  font-weight: 400;
}

.listCard
{
  margin: 0 7px 7px 7px;
}
