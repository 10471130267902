@import "../../../../../../../style-variables.scss";

.root
{
  cursor: pointer;

  &.first
  {
    padding-left: 15px;
  }

  &:hover
  {
    background-color: $hover-background-color;

    .filterButton
    {
      visibility: visible;
    }
  }

  .filterButton
  {
    visibility: hidden;

    &.showFilter
    {
      visibility: visible;
    }
  }

  .controls
  {
    visibility: hidden;
  }

  &.editMode
  {
    .controls
    {
      visibility: visible;
    }
  }

  .content
  {
    display: flex;
    align-items: center;

    .data
    {
      flex: 1 1 auto;
    }

    .appendix
    {

    }
  }

  &.hidden
  {
    color: $light-text-color;
  }
}
