@import "../.././../../style-variables.scss";

.root
{
  padding: $page-margin;

  .sourceFields
  {
    max-height: 80vh;
    overflow-y: auto !important;
  }
}
