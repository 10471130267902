.root
{
  background-color: rgba(0, 0, 0, 0.02);
  margin: 0px 3px;
  display: flex;
  flex-direction: column;
  min-width: 250px;

  .header
  {

  }

  .list
  {
    flex: 1 1 auto;
    padding: 0px 5px;

    .add
    {
      visibility: hidden;
    }

    &:hover
    {
      .add
      {
        visibility: visible;
      }
    }
  }
}
