@import "../../../../style-variables.scss";

.label
{
  display: block;
  text-transform: lowercase;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  text-align: center;
  margin-top: 15px;
  color: $secondary-text-color;

  &.first
  {
    margin-top: 0px;
  }
}
