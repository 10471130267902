@import "../.././../../style-variables.scss";

.root
{
  height: 100%;
  position: relative;

  .body
  {
    // Was used for performance increase, but does not make a difference anymore
    // (and this does not work on iOS and MacOS):
    // Add absolute positioning here because it peforms much better while dragging
    //position: absolute;
    //top: 0;
    //right: 0;
    //bottom: 0;
    //left: 0;

    padding: 5px;
    overflow: auto;
    height: 100%; // for IE11

    .sections
    {
      height: 100%;
    }
  }
}
