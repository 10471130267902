@import "../.././../../../../style-variables.scss";

.root
{
  cursor: pointer;

  &:hover
  {
    background-color: $hover-background-color;
  }

  &.highlighted
  {
    background-color: $visited-background-color;

    &:hover
    {
      background-color: $hover-background-color;
    }
  }

  &.loading
  {
    td
    {
      color: $secondary-text-color;
    }
  }

  &.inactive {
    td {
      color: red;
    }
  }
}
