.root
{
  // this competes with the email address recipient selectbox
  // z-index: 1000;

  .playGround
  {
    position: relative;
  }

  .playgroupSidebar
  {
    width: 450px;
    position: sticky;
    top: 0;

    &.noSidebar
    {
      display: none;
    }

    &.smallScreen
    {
      width: 300px;
    }
  }

  // Hide advanced property group from block editor
  [class~="block-editor-block-inspector__advanced"]
  {
    display: none;
  }

  &.hideLayoutControls
  {
    [class~="block-list-appender"],
    [class~="editor-block-list__insertion-point-inserter"],
    [class~="editor-block-mover"],
    [class~="editor-block-contextual-toolbar"],
    [class~="editor-block-list__block-edit"]:before,
    [class~="editor-block-list__breadcrumb"],
    [class~="editor-block-list__insertion-point"]
    {
      display: none !important;
    }

    [class~="editor-styles-wrapper"] [data-block]
    {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    [class~="editor-block-list__layout"]
    {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    [class~="components-popover"]
    {
      display: none;
    }
  }

  &:not(.hideLayoutControls)
  {
    // This is to show the block inserter
    [class~="block-editor-block-list__block"]
    {
      margin-bottom: 15px;
    }
  }

  //[class~="editor-styles-wrapper"] [data-block]
  //{
  //  //overflow-x: hidden;
  //}
  //
  ///* Wp overrides */
  //
  //[class~="block-editor-block-mover"]
  //{
  //  opacity: 1 !important;
  //}
  //
  ///* Decrease block padding */
  //
  //[class~="editor-block-list__layout"]
  //{
  //  padding-left: 10px !important;
  //  padding-right: 10px !important;
  //}
  //
  //
  ///* Wp settings panel overrides */
  //
  //[class~="components-panel__row"]
  //{
  //  label
  //  {
  //    max-width: none !important;
  //  }
  //}
  //
  ///* Fix drop down menu positioning */
  //
  //[class~="components-popover"]
  //{
  //  position: static !important;
  //  margin-top: 30px !important;
  //
  //  /* Menu arrow position fix */
  //
  //  &:before, &:after
  //  {
  //    margin-left: -24px !important;
  //  }
  //}
  //
  ///* Do not show arrow on block type changer menu */
  //
  //[class~="block-editor-block-switcher"]
  //{
  //  [class~="components-popover"]
  //  {
  //    &:before, &:after
  //    {
  //      display: none;
  //    }
  //  }
  //}
  //
  //[class~="block-editor-button-block-appender"]
  //{
  //  background-color: unset !important;
  //  border: none !important;
  //  outline: none !important;
  //}
  //
  //[class~="block-editor-block-list__layout"]
  //{
  //  [class~="block-editor-block-list__block"]
  //  {
  //    padding-left: 10px;
  //    padding-right: 10px;
  //
  //    ul
  //    {
  //      padding: 0;
  //    }
  //
  //    ol
  //    {
  //      padding: 0;
  //    }
  //  }
  //}
  //
  //// Fix for menus appearing below other components.
  //// Update: removed this because the popper in the Quill for text font size selection appears under the other components.
  //// -----------------------------------------------
  ////[class~="block-editor-block-list__block"]
  ////{
  ////  &[class~="has-child-selected"], &[class~="is-hovered"]
  ////  {
  ////    z-index: 10000;
  ////
  ////    [class~="block-editor-block-list__block-edit"]
  ////    {
  ////      > div:nth-child(2)
  ////      {
  ////        z-index: 200;
  ////      }
  ////    }
  ////  }
  ////}
  //
  ///* Hide "Add Block" label on button */
  //[class~="block-editor-button-block-appender"]
  //{
  //  padding: 0;
  //
  //  span
  //  {
  //    display: none;
  //  }
  //}
  //
  ///* Hide default color palette items */
  //[class~="components-color-palette__item-wrapper"]
  //{
  //  display: none;
  //}
  //
  //[class~="screen-reader-text"]
  //{
  //  display: none;
  //}
  //
  //[class~="editor-block-inspector__advanced"]
  //{
  //  display: none;
  //}
  //
  //[class~="block-editor-inserter"]
  //{
  //  // To ensure that it overlays over the attachment uploader (in email templates)
  //  z-index: 1;
  //}
  //
  ///********************************************
  //Styles from project: g-editor
  //*********************************************/
  //
  //@media (min-width: 600px) {
  //  position: relative;
  //}
  //
  //@media (min-width: 782px) {
  //  .edit-post-sidebar {
  //    top: 56px;
  //  }
  //}
  //
  //* {
  //  box-sizing: border-box;
  //
  //  &:focus {
  //    outline-width: 0;
  //  }
  //}
  //
  //input {
  //  margin: 1px;
  //}
  //
  //input[type=checkbox] {
  //  border: 1px solid #b4b9be;
  //  background: #fff;
  //  color: #555;
  //  clear: none;
  //  cursor: pointer;
  //  display: inline-block;
  //  line-height: 0;
  //  height: 16px;
  //  margin: -4px 4px 0 0;
  //  outline: 0;
  //  padding: 0 !important;
  //  text-align: center;
  //  vertical-align: middle;
  //  width: 16px;
  //  min-width: 16px;
  //  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  //  transition: .05s border-color ease-in-out;
  //}
  //
  //.edit-post-layout {
  //  padding-top: 0;
  //
  //  @media (min-width: 960px) {
  //    padding-top: 0;
  //  }
  //  @media (min-width: 600px) {
  //    padding-top: 0;
  //  }
  //
  //  .edit-post-header,
  //  .edit-post-sidebar,
  //  .components-popover {
  //    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  //    font-size: 13px;
  //  }
  //
  //  .edit-post-header {
  //    @media (min-width: 782px) {
  //      position: sticky;
  //      left: 0;
  //      top: 0;
  //    }
  //  }
  //
  //  .components-notice-list {
  //    @media (min-width: 782px) {
  //      left: 0;
  //    }
  //
  //    .notice,
  //    div.error,
  //    div.updated {
  //      background: #fff;
  //      border-left: 4px solid #fff;
  //    }
  //
  //    .notice-alt {
  //      box-shadow: none;
  //    }
  //
  //    .notice-success.notice-alt {
  //      background-color: #ecf7ed;
  //    }
  //
  //    .notice-success,
  //    div.updated {
  //      border-left-color: #46b450;
  //    }
  //
  //    .notice-dismiss {
  //      position: absolute;
  //      top: 0;
  //      right: 1px;
  //      border: none;
  //      margin: 5px;
  //      padding: 9px;
  //      background: 0 0;
  //      color: #72777c;
  //      cursor: pointer;
  //    }
  //  }
  //
  //  .edit-post-text-editor {
  //    @media (min-width: 960px) {
  //      padding-left: 20px;
  //      padding-right: 20px;
  //    }
  //
  //    .edit-post-text-editor__body {
  //      padding-top: 0;
  //      @media (min-width: 782px) {
  //        padding-top: 0px;
  //      }
  //      @media (min-width: 600px) {
  //        padding-top: 0px;
  //      }
  //
  //      .editor-post-title {
  //        @media (min-width: 600px) {
  //          padding: 5px 0;
  //        }
  //      }
  //    }
  //  }
  //
  //  .edit-post-sidebar {
  //    .edit-post-sidebar__panel-tabs {
  //      ul {
  //        list-style: none;
  //        margin: 0;
  //        padding: 0;
  //      }
  //    }
  //  }
  //}
  //
  //.screen-reader-text,
  //.screen-reader-text span,
  //.ui-helper-hidden-accessible {
  //  border: 0;
  //  clip: rect(1px, 1px, 1px, 1px);
  //  -webkit-clip-path: inset(50%);
  //  clip-path: inset(50%);
  //  height: 1px;
  //  margin: -1px;
  //  overflow: hidden;
  //  padding: 0;
  //  position: absolute;
  //  width: 1px;
  //  word-wrap: normal !important;
  //}
  //
  //.edit-post-visual-editor {
  //  font-family: inherit;
  //
  //  p {
  //    font-family: inherit;
  //  }
  //
  //  .editor-post-title,
  //  .editor-post-title__block,
  //  .editor-block-list__block,
  //  .editor-default-block-appender {
  //    max-width: initial;
  //    margin-left: 35px;
  //    margin-right: 35px;
  //  }
  //
  //  .editor-post-title > div {
  //    @media (min-width: 600px) {
  //      margin-left: 0;
  //      margin-right: 0;
  //    }
  //  }
  //
  //  .editor-post-title {
  //    .editor-post-title__input {
  //      font-family: inherit;
  //    }
  //  }
  //
  //  .editor-block-list__layout {
  //    .editor-block-list__block {
  //      &.is-selected,
  //      &.is-hovered {
  //        outline-width: 0;
  //      }
  //    }
  //
  //    .editor-default-block-appender__content {
  //      font-family: inherit;
  //    }
  //  }
  //}
  //
  //.components-color-palette__item:focus:after {
  //  top: -3px;
  //  left: -3px;
  //}
  //
  //// Hide links to php pages
  //a[href^="edit.php"],
  //a[href^="post.php"],
  //a[href^="options-permalink.php"] {
  //  display: none;
  //}
  //
  //// Hide publish button
  //.editor-post-publish-button {
  //  display: none;
  //}
  //
  ///********************************************
  //End styles from g-editor
  //*********************************************/
}
