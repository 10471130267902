@import "../../../../../style-variables.scss";

.root
{
  border: 1px dashed $divider-color;
  cursor: pointer;

  .code
  {
    display: none;
  }

  &:hover
  {
    border-color: $primary-color;
    background-color: rgba(255,255,255,0.2);

    .translation
    {
      display: none;
    }

    .code
    {
      display: inline-block;
    }
  }
}
