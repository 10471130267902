.root
{
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
  overflow: auto;

  &.wallpaper
  {
    .header
    {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  .header
  {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;

    .items
    {
      flex: 1 1 auto;
    }

    .appendix
    {

    }
  }

  .body
  {
    flex: 1 1 auto;
    position: relative;
  }
}
