@import '../../../../../../style-variables.scss';

.root
{
  .fileSizeWrapper
  {
    min-width: 75px;
    text-align: right;
  }

  .fileSize
  {
    font-size: 11px;
    color: $secondary-text-color;
    text-align: right;
  }

  .menuButtonContainer
  {
    visibility: hidden;

    &.hasInset
    {
      margin-right: -10px !important;
      margin-left: 5px !important;
    }
  }

  &:hover
  {
    .menuButtonContainer
    {
      visibility: visible;
    }
  }
}
