@import "../../../../../style-variables.scss";

.root
{
  &:not(:hover)
  {
    border-color: transparent !important;
    border-left-color: rgb(230, 230, 230) !important;
    border-right-color: rgb(230, 230, 230) !important;
  }

  border-width: 1px !important;
  border-radius: 0 !important;
  margin: 0 -1px;

  &:not(.disabled)
  {
    &:hover
    {
      // border-color: rgb(230, 230, 230) !important;
      border-width: 1px !important;
    }
  }
}
