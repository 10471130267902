@import "../../../../../style-variables";


.root
{
  white-space: pre;
}

.browserContainer
{

}

.menu
{
  border-right: 1px solid $divider-color;
}

.fieldItemLabel
{
  color: $secondary-text-color;
  font-size: 13px;
}
