.root
{
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100px;
    border-right: 1px solid rgb(230, 230, 230);
    border-bottom: 1px solid rgb(230, 230, 230);
    background-color: white;
    cursor: pointer;

    &:hover
    {
        &:not(.selected)
        {
            .background
            {
                opacity: 0.2;
            }
        }
    }
}

.content
{
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.fr
{
    border-top: 1px solid rgb(230, 230, 230);
}

.fc
{
    border-left: 1px solid rgb(230, 230, 230);
}

.fr.fc
{
    border-top-left-radius: 5px;
}

.lr.fc
{
    border-bottom-left-radius: 5px;
}

.fr.lc
{
    border-top-right-radius: 5px;
}

.ri
{

}

.bi
{

}

.root:not(.ri):not(.bi)
{
    border-bottom-right-radius: 5px;
}
