.browser
{
  width: 500px;
  height: 100%;

  .innerBrowser
  {
    position: fixed;
    top: 92px;
    bottom: 0;
    right: 25px;
    width: 500px;
  }
}
