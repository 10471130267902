@import "../../../../../style-variables";

.root
{
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 400;
  color: $secondary-text-color;

  &.active
  {
    color: $primary-color;
  }

  &:hover
  {
    background-color: $hover-background-color;
  }

  .dropdownButton
  {

  }
}
