.root
{
  .favoriteButton
  {
    display: none;
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  &:hover
  {
    .favoriteButton
    {
      display: block;
    }
  }
}

.header
{
  .headerButtons
  {
    display: none;
  }

  &:hover
  {
    .headerButtons
    {
      display: block;
    }
  }
}