@import "../../../../../style-variables.scss";

/** Quill styling overrides */
.ql-container.ql-snow
{
  border: none;
}

.ql-toolbar.ql-snow
{
  display: none;
  border: none;
  border-bottom: 1px solid $form-field-underline-color;
}

.rich-textbox-editor > .quill > .ql-container > .ql-editor.ql-blank::before
{
  font-size: $input-font-size;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: 'Normal';
  font-size: $input-font-size !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: 'Large';
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: 'Huge';
  font-size: 18px !important;
}

/** RichTextEditor */
.rich-textbox-editor {
  position: relative;
  word-break: break-word;
  >.quill {

    >.ql-container {
      width: 100%;

      >.ql-editor {
        font-family: $font-stack;
        font-size: $input-font-size;
        font-weight: $input-font-size;
        line-height: 1.46429em;
        // color: rgba(0, 0, 0, 0.87);
        padding: 0;
        overflow-y: auto;

        &.ql-blank::before {
          font-style: normal;
        }
      }
    }

    >.ql-toolbar {
      display: none;
    }
  }
}

/** RichTextViewer */
.rich-textbox-viewer {
  word-break: break-word;
  >p {
    margin: 0px;
  }

  .ql-align-right
  {
    text-align: right;
  }
  .ql-align-center
  {
    text-align: center;
  }

  ul > li
  {
    list-style-type: none;
  }

  ul > li::before
  {
    content: '\2022';
    margin-left: -0.6em;
    margin-right: 0.3em;
    text-align: right;
  }

  .ql-size-small
  {
    font-size: 0.75em;
  }
  .ql-size-large
  {
    font-size: 1.5em;
    line-height: 1.1;
  }
  .ql-size-huge {
    font-size: 2.5em;
    line-height: 1.2;
  }

  .computed
  {
    min-width: 5px;
    border-bottom: 1px dotted;
  }
}

/** Rendering in text-editor widget */
.widget-text-editor
{
  &.blur
  {
    padding-top: 40px;
  }
}

/** Block in react quill */
computation { display: inline-block; }
