@import "../../../../style-variables.scss";

//@-o-keyframes fadeIt
//{
//  0%   { background-color: rgba($hover-background-color, 1); }
//  100% { background-color: rgba($hover-background-color, 0); }
//}
//
//@keyframes fadeIt
//{
//  0%   { background-color: rgba($hover-background-color, 1); }
//  100% { background-color: rgba($hover-background-color, 0); }
//}

.dragging
{
  user-select: none;
  // background-color: $hover-background-color;
}

//.dropped
//{
//  background-image: none !important;
//  -o-animation: fadeIt 1.5s ease-in-out;
//  animation: fadeIt 1.5s ease-in-out;
//}
